import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  Row,
  Col,
  Container,
  Nav,
  InputGroup,
  Button,
  FormControl,
} from "react-bootstrap";

import playace from "../../../assets/logo_white.png";

import { Mobile, Default } from "../../helpers/Breakpoints";

import { useLocation, NavLink, useHistory } from "react-router-dom";
import { subscribeNewsLetter } from "../../../api/requests";
import { checkIsWebView, showErrorToast, showSuccessToast } from "../../../utils/utils";
import ContactUsModal from "../Home/ContactUsModal";
import { useDispatch, useSelector } from "react-redux";
import { updateIsHost } from "../../../actions/authActions";
import { RenderUserObj } from "../../helpers";

export default function Footer() {
  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();

  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const { isWebView } = checkIsWebView();

  const dispatch = useDispatch();
  const history = useHistory();

  const authState = useSelector((state) => state.auth);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const auth = RenderUserObj(useSelector((state) => state.auth.data));

  const handleClick = () => {
    const urlParams = new URLSearchParams(location.search);

    urlParams.set("page", "1");
    urlParams.set("filter", "all");
    history.push({ search: urlParams.toString(), pathname: "/parties" });

    dispatch(updateIsHost(false));
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    if (validateEmail(email)) {
      // Handle successful subscription (e.g., send email to backend)
      setValidationMessage("");
      const response = await subscribeNewsLetter({ email });

      if (response.status === "success") {
        showSuccessToast(response.message);
        setEmail("");
      } else {
        showErrorToast(response.message);
      }
    } else {
      setValidationMessage("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // const [shouldHide, setShouldHide] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };

  if (isWebView) {
    return <></>
  }

  return (
    <footer
      className={
        location.pathname === "/contact" ||
          location.pathname === "/referral" ||
          location.pathname === "/dashboard" ||
          location.pathname === "/list-space" ||
          location.pathname === "/host-party"
          ? `darkBG text-white pt-2`
          : `darkBG text-white pt-2 mTop`
      }
      id={location.pathname.startsWith("/parties/") ? "padeightRem" : ""}
    >
      <Container>
        <div className="row">
          <div className="col">
            <img src={playace} alt="playace-logo" width="160" />
          </div>
        </div>

        <Row className="mt-5 footerCont">
          {/* <Default> */}
          <Col md={2} sm={12}>
            <Nav as="ul" className="flex-column footer-nav">
              {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/faq"
                    className="text-white fw-bold"
                  >
                    FAQ
                  </Nav.Link>
                </Nav.Item> */}
              <h3>Platform</h3>
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/about" className="text-white">
                  <div onClick={scrollToTop}>About Us</div>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/host-party" className="text-white">
                  Host Party
                </Nav.Link>
              </Nav.Item>
              {(!authState?.isHost) && (
                <Nav.Item as="li">
                  <Nav.Link
                    // to="/parties?page=1&filter=all"
                    className="text-white"
                    onClick={handleClick}
                  >
                    Explore Parties
                  </Nav.Link>
                </Nav.Item>
              )}
              {/* <Nav.Item as="li">
                <Nav.Link as={NavLink} to="/referral" className="text-white">
                  Refer A Friend
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.youtube.com/channel/UC8L2VO2huuwOJKqlMEOauBA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    Video Gallery
                  </Nav.Link>
                </Nav.Item> */}
              {/* <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/contact"
                    className="text-white fw-bold"
                  >
                    CAREERS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/#" className="text-white fw-bold">
                    BLOG
                  </Nav.Link>
                </Nav.Item> */}
            </Nav>
          </Col>
          <Col md={2} sm={12}>
            <Nav as="ul" className="flex-column footer-nav">
              <h3>BRANDS</h3>

              <Nav.Item as="li">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/919820329205?text=I'd like to collaborate / feature my brand. Can I get more info about this?"
                  className="text-white"
                >
                  Feature your brand
                </a>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={2} sm={12}>
            <Nav as="ul" className="flex-column footer-nav">
              <h3>HELP</h3>
              <Nav.Item as="li">
                <Nav.Link
                  as={NavLink}
                  to="/terms"
                  className="text-white"
                  onClick={scrollToTop}
                >
                  Terms And Conditions
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  as={NavLink}
                  to="/privacy"
                  className="text-white"
                  onClick={scrollToTop}
                >
                  Privacy Policy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  as={NavLink}
                  to="/refunds"
                  className="text-white"
                  onClick={scrollToTop}
                >
                  Refunds
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  as={NavLink}
                  to="/disclaimer"
                  className="text-white"
                  onClick={scrollToTop}
                >
                  Disclaimer
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link
                  className="text-white"
                  onClick={() => setModalShow(true)}
                >
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col md={1} sm={12}></Col>

          <Col md={5} sm={12} className="">
            <p className=" subscribeText">Subscribe to our newsletter</p>
            <InputGroup className="mb-3 text-muted relative">
              <FormControl
                placeholder="Email"
                aria-label="Email address"
                size="lg"
                className="customInput"
                value={email}
                onChange={handleInputChange}
              />
              <Button
                variant="light"
                size="lg"
                className="subsBtn"
                onClick={handleSubscribe}
              >
                Subscribe
              </Button>
            </InputGroup>
            {validationMessage && (
              <p style={{ color: "red" }}>{validationMessage}</p>
            )}
            <div className="social d-flex align-items-center align-content-center">
              <p className="line-height-footer">Follow us on</p>
              <a
                href="https://www.youtube.com/@playacehouseparties2121"
                className="d-block mb-3"
                target="blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-youtube"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="" />
                  <path d="M2 8a4 4 0 0 1 4 -4h12a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-8z" />
                  <path d="M10 9l5 3l-5 3z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/playaceapp/"
                className="d-block mb-3"
                target="blank"
              >
                {/* <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                </svg>
              </a>
              <a
                href="https://twitter.com/playaceapp"
                className="d-block mb-3"
                target="blank"
              >
                {/* <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-x"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                  <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/playace.co/"
                className="d-block mb-3"
                target="blank"
              >
                {/* <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                  <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                  <path d="M16.5 7.5l0 .01" />
                </svg>
              </a>
            </div>
          </Col>
          {/* </Default> */}
          {/* <Mobile>
            <Col xs={6}>
              <Nav as="ul" className="flex-column footer-nav">
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/about"
                    className="text-white fw-bold"
                  >
                    ABOUT US
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/terms"
                    className="text-white fw-bold"
                  >
                    TERMS AND CONDITIONS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/referral"
                    className="text-white fw-bold"
                  >
                    REFER A FRIEND
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    href="https://www.youtube.com/channel/UC8L2VO2huuwOJKqlMEOauBA"
                    className="text-white fw-bold"
                  >
                    VIDEO GALLERY
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={6}>
              <Nav as="ul" className="flex-column footer-nav">
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/privacy"
                    className="text-white fw-bold"
                  >
                    PRIVACY POLICY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link href="/refunds" className="text-white fw-bold">
                    REFUNDS
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    as={NavLink}
                    to="/disclaimer"
                    className="text-white fw-bold"
                  >
                    DISCLAIMER
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} className="mt-5">
              <p className="m-0">
                Keep yourself updated about the latest parties!
              </p>
              <InputGroup className="mb-3 text-muted">
                <FormControl
                  placeholder="Email address"
                  aria-label="Email address"
                  size="lg"
                />
                <InputGroup.Append>
                  <Button variant="light" size="lg" className="text-muted">
                    Subscribe
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              <div className="social d-flex justify-content-between align-items-center align-content-center">
                <p>Follow us on:</p>
                <a
                  href="https://www.facebook.com/playaceapp/"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://twitter.com/playaceapp"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
                <a
                  href="https://www.instagram.com/playace.co/"
                  className="d-block mb-3"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-white"
                  ></FontAwesomeIcon>
                </a>
              </div>
            </Col>
          </Mobile> */}
        </Row>

        <div className="text-center">
          <p className="m-0 text-center kumbh">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-copyright"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M14 9.75a3.016 3.016 0 0 0 -4.163 .173a2.993 2.993 0 0 0 0 4.154a3.016 3.016 0 0 0 4.163 .173" />
            </svg>{" "}
            All rights reserved
          </p>
        </div>

        <ContactUsModal
          show={modalShow}
          handleClose={() => setModalShow(false)}
        />
      </Container>
    </footer>
  );
}
