import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import {
  URL,
  NEW_URL,
  signupUser,
  sendOtpWhatsapp,
} from "../../../api/requests";
import InputField from "../common/InputField";
import { useEffect } from "react";
import OTPComponent from "../common/OTPComponent";
import { authenticateUserRequest } from "../../../actions/authActions";
import { useDispatch } from "react-redux";
import { debounce, getErrorMessage, getLoader, regex, showErrorToast, showSuccessToast } from "../../../utils/utils";
import useForm from "../../../utils/useForm";

/*DEFAULT IS FIRST SHOWN 

VIEW SWITCHES ACCORDING TO CODE FROM AUTH.JS
*/

export function Default(props) {
  const initialValues = {
    phone: {
      value: "",
      patternErrorMessage: "Phone number is not valid",
      regex: regex.phone,
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
  };

  const { form, handleFormChange, handleSubmit } = useForm(initialValues);

  return (
    <div>
      <div className="formUpTit">
        <h3 className="text-uppercase">
          {props.newUser ? (
            <span>Join Us</span>
          ) : (
            <span>Verify your mobile number</span>
          )}
        </h3>
      </div>

      <form
        onSubmit={(e) => {
          handleSubmit(e, props.handleSubmit);
        }}
        className="commonInputCont mt-5"
      >
        <label htmlFor="phone">Mobile number</label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="Enter WhatsApp Mobile Number"
          autoFocus
          value={form.phone.value}
          onChange={handleFormChange}
        />

        {getErrorMessage(form, "phone")}

        <button type="submit" className="btn btn-primary mt-5 py-2">
          {props.newUser ? <>Continue</> : <>Get OTP</>}
        </button>
      </form>
    </div>
  );
}
export function Login(props) {
  const [loading, setLoading] = useState(false);

  const [otpTimer, setOtpTimer] = useState(60);

  useEffect(() => {
    props.setNewUser(false);
  }, [props]);

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = () => {
    let timer = 60;
    const interval = setInterval(() => {
      timer = timer - 1;
      setOtpTimer(timer);
      if (timer === 0) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const resendOTPRequest = debounce(() => {
    setLoading(true);
    sendOtpWhatsapp({ phone: props?.phone }).then((response) => {
      if (response.status === "success") {
        startTimer();
        showSuccessToast(response?.message ?? "OTP has been sent successfully");
      } else {
        showErrorToast(response?.message ?? "Something went wrong");
        console.log("error", response);
      }
    }).catch(() => {

    }).finally(() => {
      setLoading(false);
    });
  }, 500)

  return (
    <div>
      {loading && getLoader()}
      <div className="formUpTit">
        <h3 className="text-uppercase">
          <span>Verify your mobile number</span>
        </h3>
      </div>
      <OTPComponent
        phone={props.phone}
        handleClose={props.handleClose}
        handleHost={(otp) => props.handleHost(otp)}
      />
      {otpTimer == 0 ? (
        <p
          className="mt-3 make-pointer resendOtp"
          onClick={() => {
            resendOTPRequest();
          }}
        >
          Resend OTP
        </p>
      ) : (
        <p className="mt-3 make-pointer resendOtp">
          Resend OTP in {otpTimer} seconds
        </p>
      )}

      {/* {resendOTP && (
        <small className="text-success">OTP has been resent.</small>
      )} */}
    </div>
  );
}

export function Signup(props) {
  const initialValues = {
    phone: {
      value: props.phone,
      patternErrorMessage: "Phone number is not valid",
      regex: regex.phone,
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
    email: {
      value: "",
      patternErrorMessage: "Email is not valid",
      regex: regex.email,
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
    fullName: {
      value: "",
      patternErrorMessage: "Full Name is not valid",
      regex: regex.name,
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
    gender: {
      value: "male",
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
  };
  const [loading, setLoading] = useState(false);

  const { form, handleFormChange, handleSubmit } = useForm(initialValues);

  const dispatch = useDispatch();

  useEffect(() => {
    props.setNewUser(true);
  }, [props]);

  const onSignUp = debounce(async (data) => {
    try {
      setLoading(true)
      const apiRequest = {
        fullName: data.fullName,
        phone: props.phone,
        email: data.email,
        gender: data.gender
      }

      const response = await signupUser(apiRequest)
      console.log("response", response);
      if (response.status === "success") {
        showSuccessToast(response?.message ?? "Your account is created successfully");
        dispatch(authenticateUserRequest(response));
        props?.onSignup();
      }
      else {
        showErrorToast(response?.message ?? "Something went wrong");
      }
    } catch {

    } finally {
      setLoading(false);
    }

  }, 500);

  return (
    <div>
      {loading && getLoader()}
      <div className="formUpTit noCenter">
        <h3 className="text-uppercase">Sign up</h3>
        <p>Your New Social Adventure Awaits!</p>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(e, onSignUp);
        }}
        className="commonInputCont"
      >
        <div className="form-group">
          <label htmlFor="phone">
            <small>Mobile Number</small>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={form.phone.value}
            disabled
          />
          <p className="smolInputInfo">
            You’ll receive the important party updates on this number.
          </p>
          {getErrorMessage(form, "phone")}
        </div>

        <div className="form-group">
          <label htmlFor="first-name">
            <small>Name</small>
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Enter your full name"
            value={form.fullName.value}
            onChange={handleFormChange}
          />

          {getErrorMessage(form, "fullName")}
        </div>

        <div className="form-group">
          <label htmlFor="email">
            <small>Contact Info</small>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={form.email.value}
            onChange={handleFormChange}
          />

          <p className="smolInputInfo">
            We’ll send you location & party updates on email
          </p>

          {getErrorMessage(form, "email")}
        </div>

        <div className="form-group">
          <label htmlFor="gender">
            <small>Gender</small>
          </label>
          <select
            id="gender"
            name="gender"
            value={form.gender.value}
            onChange={handleFormChange}
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          {getErrorMessage(form, "gender")}
        </div>

        <Button type="submit" className="btn btn-primary mt-3">
          Sign up
        </Button>
      </form>
    </div>
  );
}
