import React, { useCallback, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import {
  Testimonial,
  Testimonials,
} from "../../../assets/testimonials/Testimonials";

import ReactPlayer from "react-player";
import Slider from "react-slick";
import ScrollContainer from "react-indiana-drag-scroll";
import { Default, Mobile } from "../../helpers/Breakpoints";

export default function ExperienceVideos() {
  const [youtubeModal, showYoutubeModal] = useState(false);
  const [videoUrl, setClickedVideoUrl] = useState("");
  const [isSwiping, setIsSwiping] = useState(false);
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleStart = useCallback((e) => {
    const startX = e.touches ? e.touches[0].clientX : e.clientX;
    const startY = e.touches ? e.touches[0].clientY : e.clientY;
    setStartPosition({ x: startX, y: startY });
    setIsSwiping(false);
  }, []);

  const handleMove = useCallback(
    (e) => {
      const currentX = e.touches ? e.touches[0].clientX : e.clientX;
      const currentY = e.touches ? e.touches[0].clientY : e.clientY;
      const distanceX = Math.abs(currentX - startPosition.x);
      const distanceY = Math.abs(currentY - startPosition.y);
      if (distanceX > 10 || distanceY > 10) {
        // Adjust the threshold as needed
        setIsSwiping(true);
      }
    },
    [startPosition]
  );

  const handleClick = (testimonial) => {
    if (!isSwiping) {
      showYoutubeModal(true);
      setClickedVideoUrl(testimonial.video);
    }
  };

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // return (
  //

  // )

  const handleTicketModal = () => {
    showYoutubeModal(false);
  };

  return (
    <section className="wholegbg_black experienceVideos_cont">
      <Modal
        backdrop="static"
        show={youtubeModal}
        onHide={handleTicketModal}
        centered
      >
        <Container className="text-center py-4">
          <span onClick={handleTicketModal} className="crossIconyt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="icon icon-tabler icons-tabler-outline icon-tabler-x"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
          </span>

          <div className="widthFull">
            <ReactPlayer url={videoUrl} playing className="widthFull" />
          </div>
        </Container>
      </Modal>

      <Container>
        <div className="titleMid">
          <h2>House Party Confessions:</h2>
          <h2>Watch Now!</h2>
        </div>
      </Container>

      {/* <div className="videosCont"> */}

      <Default>
        <Slider {...settings}>
          {Testimonials.map((testimonial) => {
            return (
              <div
                className="ytVideodiv"
                onMouseDown={handleStart}
                onMouseMove={handleMove}
                onMouseUp={() => handleClick(testimonial)}
                onTouchStart={handleStart}
                onTouchMove={handleMove}
                onTouchEnd={() => handleClick(testimonial)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="icon icon-tabler icons-tabler-filled icon-tabler-player-play"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" />
                </svg>
                <img src={testimonial.image} />
              </div>
            );
          })}
        </Slider>
      </Default>
      <Mobile>
        <ScrollContainer
          className="scroll-container"
          style={{ padding: "0 1.2rem 1.2rem 0.2rem" }}
        >
          <div className="gridVideos">
            {Testimonials.map((testimonial) => {
              return (
                <div
                  className="ytVideodiv"
                  onClick={() => {
                    showYoutubeModal(true);
                    setClickedVideoUrl(testimonial.video);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="icon icon-tabler icons-tabler-filled icon-tabler-player-play"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" />
                  </svg>
                  <img src={testimonial.image} />
                </div>
              );
            })}
          </div>
        </ScrollContainer>
      </Mobile>

      {/* </div> */}
    </section>
  );
}
