import React from "react";
import { Container } from "react-bootstrap";
import discoBall from "../../../assets/image 48.png";
import wineBottle from "../../../assets/image 35.svg";

export default function SmallInfo() {
  return (
    <section className="wholegbg_black smolInfo_cont">
      <Container>
        <div className="oddText">
          <img src={discoBall} alt="" className="infoDisco" />
          <img src={wineBottle} alt="" className="infoWine" />
          <p>
            <span>
              Playace.co is a trusted community of more than 30,000 verified
              members who have registered and attended more than 4000 house
              parties over the span of 6 years. Join us today and discover
              magical gatherings where strangers become friends and nights
              become stories.
            </span>
          </p>
        </div>
      </Container>
    </section>
  );
}
