//PACKAGE IMPORTS
import React, { useState, useEffect, Suspense, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Nav, Modal, Container } from "react-bootstrap";
import {
  Switch,
  Route,
  Link,
  NavLink,
  useLocation,
  useHistory,
  Redirect,
} from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

//ASSET IMPORTS
import playace from "../../../assets/logo_white.png";
import playaceSmall from "../../../assets/logo dog playace.svg";
import { RenderUserObj } from "../../helpers";
import DefaultDP from "../../../assets/DP.png";
import { Toaster } from "react-hot-toast";

//COMPONENT IMPORTS
import {
  Home,
  Parties,
  PartiesDetail,
  BookParty,
  Profile,
  HostAParty,
  UploadCover,
  PlanningOverview,
  BankingDetails,
  AboutUs,
  Referral,
  Contact,
  NotFoundPage,
  Refunds,
} from "../";
import Auth from "../../modules/auth/Auth";

//REDUX IMPORTS
import {
  unloadCurrentUser,
  updateCity,
  updateIsHost,
} from "../../../actions/authActions";
import Img from "react-cool-img";
import { Default, Mobile } from "../../helpers/Breakpoints";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import Disclaimer from "../Disclaimer";
import Faq from "../Faq";
import HostDetails from "../../modules/content/hostDetails";
import EventReview from "../../modules/content/eventReview";
import GuestDetails from "../../modules/content/guestDetails";
import HostParties from "../HostAParty/HostParties";
import PayOutHome from "../PayOuts/PayOutHome";
import { getCities } from "../../../api/requests";
import { axiosInstance } from "../../../api/config";
import Axios from "axios";
import { checkIsWebView } from "../../../utils/utils";

export default function Header() {
  let [expanded, setExpanded] = useState(false);
  const [show, setShow] = useState(false);

  const [showSearch, setShowSearch] = useState(true);

  const [profilePicture, setProfilePicture] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const auth = RenderUserObj(useSelector((state) => state.auth.data));
  const authState = useSelector((state) => state.auth);
  const selectedCity = useSelector((state) => state?.auth?.selectedCity?.name);

  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState(null);

  const [isCitySelectorVisible, setIsCitySelectorVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated && auth?.profileImage) {
      setProfilePicture(auth?.profileImage);
    }
  }, [auth, isAuthenticated]);

  useEffect(() => {
    let token = auth?.data?.token;
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

  }, [auth])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function usePageViews() {
    useEffect(() => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          page: {
            url: location.pathname,
            title: document.title,
          },
        },
      });
    }, [location]);
  }

  function handleClick(e) {
    e.preventDefault();
    setTimeout(() => {
      dispatch(updateIsHost(false));

      // if (
      //   location.pathname === "/dashboard" ||
      //   location.pathname === "/list-space" ||
      //   location.pathname === "/host-party/banking-details" ||
      //   location.pathname === "/host-party/planning-overview" ||
      //   location.pathname === "/host-party/planning-details" ||
      //   location.pathname === "/host-party" ||
      //   location.pathname.includes("/parties") ||

      //   location.pathname.includes("/parties")
      // ) {
      setExpanded(false);
      history.push("/");
      localStorage.setItem("state", "");
      localStorage.setItem("tokenNew", "");
      axiosInstance.defaults.headers.common["Authorization"] = ``;
      Axios.defaults.headers.common["Authorization"] = ``;
      // }
      dispatch(unloadCurrentUser(authState));
    }, 2000);
  }
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth <= 600;

    if (isMobile) {
      if (
        location.pathname.startsWith("/parties/") ||
        location.pathname.startsWith("/booking/party/")
      ) {
        setShouldHide(true);
      } else {
        setShouldHide(false);
      }
    }

    if (isAuthenticated) {
      setShow(false);
    }
    if (
      location?.pathname === "/" ||
      (location?.pathname.startsWith("/parties") &&
        !location?.pathname.match(/^\/parties\/.+$/))
    ) {
      // if city name is not in the url than set url
      const urlParams = new URLSearchParams(location.search);
      const city = urlParams.get("city");
      console.log("location.pathname", location.pathname);
      if (!city) {
        console.log("authState.selectedCity?.id", authState.selectedCity);
        urlParams.set("city", authState.selectedCity?.id);
        history.replace({ search: urlParams.toString() });
      } else {
        let foundCity = cities.find(
          (dropDownCity) => dropDownCity.id === parseInt(city)
        );
        if (foundCity != undefined) {
          dispatch(updateCity(foundCity));
        }
      }
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }

    if (location.pathname === "/dashboard" && !isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated, history, location]);

  const { isWebView } = checkIsWebView();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const citySelectorRef = useRef(null);
  const profileMenuRef = useRef(null);
  const profileButtonRef = useRef(null);
  const cityBtnRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      (cityBtnRef.current && cityBtnRef.current.contains(event.target)) ||
      (profileButtonRef.current &&
        profileButtonRef.current.contains(event.target)) ||
      (citySelectorRef.current &&
        citySelectorRef.current.contains(event.target)) ||
      (profileMenuRef.current && profileMenuRef.current.contains(event.target))
    ) {
      return;
    }
    setIsCitySelectorVisible(false);
    setIsProfileClicked(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    if (isWebView) {
      setShouldHide(true)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getCities().then((response) => {
      if (response.status === "success") {
        let tempResponse = response?.data;
        tempResponse.unshift({ id: 0, city: "All Cities" });
        setCities(tempResponse);
        const urlParams = new URLSearchParams(location.search);
        console.log("urlParams", tempResponse);
        const city = urlParams.get("city");
        let foundCity = tempResponse.find(
          (dropDownCity) => dropDownCity.id === parseInt(city)
        );
        if (foundCity) {
          dispatch(updateCity(foundCity));
        }
      }
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const city = urlParams.get("city");
    setIsCitySelectorVisible(!city);
  }, [cities]);

  const toggleCitySelector = () => {
    setIsCitySelectorVisible(!isCitySelectorVisible);
  };

  const handleCityChange = (e, city) => {
    dispatch(updateCity(city));
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("city", parseInt(city?.id)); // Add or update the 'city' query parameter
    urlParams.set("page",1);
    // Update the URL with the new query string
    history.push({ search: urlParams.toString() });

    // Scroll to the section with the class name 'attend-section'
    const section = document.querySelector(".attend-section");
    if (section) {
      const yOffset = -100; // Adjust this value as needed
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }

    toggleCitySelector();
  };

  // Check if the current path starts with '/parties/'

  const [isProfileClicked, setIsProfileClicked] = useState(false);

  const handleProfileClick = () => {
    setIsProfileClicked(!isProfileClicked);
  };
  usePageViews();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const urlParams = new URLSearchParams(location.search);
      urlParams.set("search", search);
      urlParams.set("page", 1);

      history.push({
        pathname: "/parties",
        search: urlParams.toString(),
      });
    }
  };

  const getButtonClass = (path) => {
    return location.pathname === path ? "active-button" : "inactive-button";
  };

  const handleClicked = () => {
    setExpanded(false);
    scrollToTop();
  };
  return (
    <React.Fragment>
      {/* <ScrollToTop /> */}
      {!shouldHide && (
        <Navbar
          bg=""
          expand="lg"
          fixed="top"
          className={isAuthenticated ? `padSticky` : "padSticky"}
          style={
            location.pathname === "/referral" ||
              location.pathname === "/contact" ||
              location.pathname === "/dashboard"
              ? { border: "none" }
              : { borderBottom: "" }
          }
          expanded={expanded}
        >
          <Container>
            {authState?.isHost && isAuthenticated ? (
              <Navbar.Brand as={Link} to="/">
                <img
                  src={playaceSmall}
                  alt="playace-logo"
                  width="40"
                  className="smolLogo"
                />
              </Navbar.Brand>
            ) : (
              <Navbar.Brand as={Link} to="/">
                <img src={playace} alt="playace-logo" width="130" />
              </Navbar.Brand>
            )}

            {authState?.isHost &&
              isAuthenticated &&
              auth?.data?.accountType == "host" && (
                <div className="hostNavbar">
                  <div className="innerBox">
                    <button className={getButtonClass("/host-party")}>
                      <Link to="/host-party" className="button-link">
                        Hosting overview
                      </Link>
                    </button>
                    {/* <button className={getButtonClass("/payout")}>
                    <Link to="/payout" className="button-link">
                      Payouts
                    </Link>
                  </button> */}
                    <button className={getButtonClass("/dashboard")}>
                      <Link to="/dashboard" className="button-link">
                        Profile
                      </Link>
                    </button>
                  </div>
                </div>
              )}

            {!authState?.isHost && showSearch && (
              <div className="searchNall">
                <div className="middleSearchCont">
                  <div className="iconSearch">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                      <path d="M21 21l-6 -6" />
                    </svg>
                  </div>
                  <div className="lefInput">
                    <input
                      type="text"
                      value={search}
                      onKeyDown={handleKeyDown}
                      placeholder="Search for house parties, hosts, etc"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>

                  <div
                    className="happeningInlocation"
                    onClick={toggleCitySelector}
                    ref={cityBtnRef}
                  >
                    <p>
                      {authState?.selectedCity?.city ?? "Happening in Location"}{" "}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 9l6 6l6 -6" />
                    </svg>
                  </div>
                </div>
                {isCitySelectorVisible && (
                  <div
                    className="city-selector-container"
                    ref={citySelectorRef}
                  >
                    {cities?.map((city) => (
                      <div key={city?.city} className="city-option">
                        <input
                          type="radio"
                          id={city.id}
                          name="city"
                          value={city?.city}
                          checked={city?.city === authState?.selectedCity?.city}
                          onChange={(e) => handleCityChange(e, city)}
                        />
                        <label htmlFor={city.id}>{city.city}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            <div className="profile_cont">
              <button className="btn btn-reg thirdBtn customizeBtn">
                {isAuthenticated && auth?.data?.accountType == "host" ? (
                  <>
                    {authState?.isHost ? (
                      <Link
                        to="/"
                        onClick={() => {
                          dispatch(updateIsHost(false));
                        }}
                      >
                        Switch To Attending
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-confetti"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5h2" />
                          <path d="M5 4v2" />
                          <path d="M11.5 4l-.5 2" />
                          <path d="M18 5h2" />
                          <path d="M19 4v2" />
                          <path d="M15 9l-1 1" />
                          <path d="M18 13l2 -.5" />
                          <path d="M18 19h2" />
                          <path d="M19 18v2" />
                          <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z" />
                        </svg>
                      </Link>
                    ) : (
                      <Link
                        to="/host-party"
                        onClick={() => {
                          dispatch(updateIsHost(true));
                        }}
                      >
                        Switch To Hosting
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-confetti"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5h2" />
                          <path d="M5 4v2" />
                          <path d="M11.5 4l-.5 2" />
                          <path d="M18 5h2" />
                          <path d="M19 4v2" />
                          <path d="M15 9l-1 1" />
                          <path d="M18 13l2 -.5" />
                          <path d="M18 19h2" />
                          <path d="M19 18v2" />
                          <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z" />
                        </svg>
                      </Link>
                    )}
                  </>
                ) : (
                  <Link to="/host-party">
                    Host A Party
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-confetti"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 5h2" />
                      <path d="M5 4v2" />
                      <path d="M11.5 4l-.5 2" />
                      <path d="M18 5h2" />
                      <path d="M19 4v2" />
                      <path d="M15 9l-1 1" />
                      <path d="M18 13l2 -.5" />
                      <path d="M18 19h2" />
                      <path d="M19 18v2" />
                      <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z" />
                    </svg>
                  </Link>
                )}

                {/* <a href="/host-party">
                Host A Party
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-confetti"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M4 5h2" />
                  <path d="M5 4v2" />
                  <path d="M11.5 4l-.5 2" />
                  <path d="M18 5h2" />
                  <path d="M19 4v2" />
                  <path d="M15 9l-1 1" />
                  <path d="M18 13l2 -.5" />
                  <path d="M18 19h2" />
                  <path d="M19 18v2" />
                  <path d="M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z" />
                </svg>
              </a> */}
              </button>
              <button
                className="btn btn-reg darkgrayBtn customizeBtn"
                onClick={handleProfileClick}
                ref={profileButtonRef}
              >
                <a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-user"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                  </svg>
                </a>
              </button>

              {isProfileClicked && (
                <div className="profileClicked" ref={profileMenuRef}>
                  {!isAuthenticated ? (
                    <Nav.Link
                      className="mx-2 fw-bold"
                      onClick={() => {
                        handleShow();
                        setExpanded(false);
                        handleProfileClick();
                      }}
                    >
                      Login/Sign up
                    </Nav.Link>
                  ) : (
                    <>
                      <Nav.Link
                        className="mx-2 fw-bold"
                        as={NavLink}
                        to="/dashboard"
                        onClick={() => {
                          setExpanded(false);
                          handleProfileClick();
                        }}
                      >
                        Profile
                      </Nav.Link>
                      <Nav.Link className="mx-2 fw-bold" onClick={handleClick}>
                        Logout
                      </Nav.Link>
                    </>
                  )}
                </div>
              )}
            </div>

            {/* <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          /> */}
            {/* <Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto my-2 text-uppercase align-items-center">
							<Nav.Link
								as={NavLink}
								className="mx-2 fw-bold"
								activeClassName="selected"
								exact
								to="/"
								onClick={() => handleClicked()}
							>
								Home
							</Nav.Link>
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/parties/page/1"
								onClick={() => handleClicked()}
							>
								Attend a party
							</Nav.Link>
							<Nav.Link
								className="mx-2 fw-bold"
								as={NavLink}
								activeClassName="selected"
								exact
								to="/host-party"
								onClick={() => handleClicked()}
							>
								Host a party
							</Nav.Link>
							{!isAuthenticated ? (
								<Nav.Link
									className="mx-2 fw-bold"
									onClick={() => {
										handleShow();
										setExpanded(false);
									}}
								>
									Sign up/Login
								</Nav.Link>
							) : (
								<>
									<Nav.Link
										className="mx-2 fw-bold"
										as={NavLink}
										to="/dashboard"
										onClick={() => setExpanded(false)}
									>
										<div
											className="d-flex justify-content-center align-items-center"
											style={{
												height: '40px',
												width: '40px',
												borderRadius: '50%',
												overflow: 'hidden',
											}}
										>
											<Img
												placeholder={DefaultDP}
												error={DefaultDP}
												src={profilePicture}
												alt="user profile"
												className="rounded-circle cursor-pointer"
												height="40"
												width="40"
											/>
										</div>
									</Nav.Link>
									<Nav.Link className="mx-2 fw-bold" onClick={handleClick}>
										Logout
									</Nav.Link>
								</>
							)}
						</Nav>
					</Navbar.Collapse> */}
            <Default>
              <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                dialogClassName="modal-dimensions borderandWidth"
                backdrop="static"
                centered
              >
                <Auth handleClose={handleClose} />
              </Modal>
            </Default>
            <Mobile>
              <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                centered
              >
                <Auth handleClose={handleClose} />
              </Modal>
            </Mobile>
          </Container>
        </Navbar>
      )}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="?paid=true" component={Home} />
        <Route exact path="/parties" component={Parties} />
        <Route exact path="/parties/:id" component={PartiesDetail} />
        <Route
          exact
          path="/hostDetails/:hostId/:eventId"
          component={HostDetails}
        />
        <Route
          exact
          path="/guestDetails/:participantId/:eventId"
          component={GuestDetails}
        />

        <Route exact path="/event-reviews/:eventId" component={EventReview} />
        <Route exact path="/booking/party/:id" component={BookParty} />
        <Route exact path="/dashboard" component={Profile} />
        <Route exact path="/host-party" component={HostAParty} />
        <Route exact path="/payout" component={PayOutHome} />
        <Route exact path="/host-party/upload-cover" component={UploadCover} />
        <Route
          exact
          path="/host-party/planning-overview"
          component={PlanningOverview}
        />
        <Route
          exact
          path="/host-party/banking-details"
          component={BankingDetails}
        />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/referral" component={Referral} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/refunds" component={Refunds} />
        <Route exact path="/terms" component={TermsAndConditions} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <Route exact path="/faq" component={Faq} />
        <Route path="*">
          <NotFoundPage />
        </Route>
        <Route exact path="/dashboard">
          {!isAuthenticated ? (
            <Redirect to="/" />
          ) : (
            <Suspense fallback={<p>Loading...</p>}>
              <Profile />
            </Suspense>
          )}
        </Route>
      </Switch>
      <Toaster />
    </React.Fragment>
  );
}
