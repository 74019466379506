import React, { useState } from "react";
import NavTab from "./NavTab";
import HouseParties from "./HouseParties";
import SpaceListing from "./SpaceListing";
import { Container } from "react-bootstrap";

export default function TermsAndConditions() {
  let [Tab, setTab] = useState("parties");
  return (
    <div style={{marginTop:"7rem"}}>
      <NavTab Tab={Tab} setTab={setTab} className="tabH"/>
      <Container className="extraFontsCont">
        {Tab === "parties" ? <HouseParties /> : <SpaceListing />}
      </Container>
    </div>
  );
}
