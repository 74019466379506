import { combineReducers } from "redux";
import authReducer from "./authReducer";
import partyReducer from "./partyReducer";
import propertyReducer from "./propertyReducer";
import hostPartyReducer from "./hostPartyReducer";
import guestBookReducer from "./guestBookReducer";
// const appReducer = combineReducers({
//     auth: authReducer
// })

export const rootReducer = combineReducers({
  auth: authReducer,
  party: partyReducer,
  property: propertyReducer,
  hostParty: hostPartyReducer,
  guestBook: guestBookReducer,

});
