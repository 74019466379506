import React from "react";
import { Container } from "react-bootstrap";

export default function HouseParties() {
  return (
    <Container>
      <h4 className="text-center maintopTit">Refund and Cancellation Policy</h4>
      <div>
        <p>
          If, as a guest, you wish to cancel a confirmed booking made via the
          Site and Services, at least 48 hours in advance of the stated start
          time of the event, you will get the refund of the entire payment made
          by you excluding the service Fees and any other taxes as applicable
          (including the Partner guest’s share), which would be the same as the
          Total Fees less service Fees i.e. Event Fees within 5-7 working days.
        </p>
        <p>
          If, as a guest, you wish to cancel a confirmed booking made via the
          Site and Services, less than 48 hours in advance of the stated start
          time of the event, you will not get any refund.
        </p>
        <p>
          If a guest cancels a confirmed booking made via the Website and
          Services, at least 48 hours in advance of the stated start time of the
          event, and the cancellation triggers the minimum number of guest
          participants not being met for the event (as mentioned by the Host in
          the Listing), the Host will have the option to go ahead with hosting
          the event or cancel the event as long as the cancellation by the Host
          is done at least 24 hours before the stated start time of the event.
          The option of cancellation in such cases can be exercised twice in a
          month.
        </p>
        <p>
          If a guest cancels a confirmed booking made via the Site and Services,
          less than 48 hours in advance of the stated start time of the event
          and the cancellation triggers the minimum number of guest participants
          not being met for the event (as mentioned by the Host in the Listing),
          the Host will need to go ahead with the event, in case there are
          further guests who had booked the event. In such an event, 100% of the
          penalty (excluding the service Fees and the applicable taxes) levied
          on the guest will be paid to the Host.
        </p>
        <p>
          A guest will be barred from participating on PLAYACE if he or she
          cancels 5 events in a 12 month calendar period. Once the guest is
          barred from participating on PLAYACE owing to cancellations on his or
          her part, he or she can de-bar himself or herself on payment of a fee
          of Rs. 500.
        </p>
        <p>
          We would advise that the Host goes ahead with the event despite the
          minimum number of guests not being met, since it would affect his /
          her ratings; to incentivise the Host in such cases, Schindae
          .Technologies Pvt. Ltd at its sole discretion may share a part of the
          service Fees with the Host. This will be communicated by Schindae
          technologies Pvt. Ltd. to the Host at the time that such situation
          arises.
        </p>
        <p>
          Penalty-free cancellation in case the host cancels prior to 7 days
          from the confirmed event. There would be no cancellation fees
          applicable in this case.If the host cancels within 7 days of the
          confirmed event, he or she is eligible to cancel twice in a month or
          three consecutive cancellations, beyond this, the Host will be barred
          from hosting on PLAYACE.
        </p>
        <p>
          Once the Host cancels, we will refund the guest within a commercially
          reasonable time. <br />
          Once the Host is barred from hosting on PLAYACE owing to cancellations
          on his or her part, he or she can de-bar himself or herself on payment
          of a fee of Rs. 500.
        </p>
        <p>
          In addition to the above mentioned, if, as a Host, you cancel a
          confirmed booking, Schindae technologies Pvt. Ltd. may apply penalties
          or consequences to you or your Listing, including (i) publishing an
          automated review on your Listing indicating that a reservation was
          cancelled, (ii) keeping the calendar for your Listing unavailable or
          blocked for the dates of the cancelled booking, or (iii) imposing a
          cancellation fee (to be withheld from your future payouts). You will
          be notified of the situations in which a cancellation fee applies when
          you decide to cancel. We may at our sole discretion, in the event of
          the host cancelling the event, ask for a security deposit of INR 5,000
          for future events.
        </p>
        <p>
          In certain circumstances, Schindae Technologies Pvt. Ltd. may decide,
          in its sole discretion, that it is necessary or desirable to cancel a
          confirmed booking made via the Website and Services. This may be for
          reasons which may be considered as being extenuating circumstances.
        </p>
      </div>
    </Container>
  );
}
