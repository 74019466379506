import React from 'react';
import { Nav } from 'react-bootstrap';

import styles from './DashboardNav.module.scss';

//NAVBAR ON THE DASHBOARD
function DashboardNav({ page, setPage }) {
    return (
        <div className={styles.nav}>
            <Nav.Item onClick={() => setPage('profile')} className={page === "profile" ? `fw-bold make-pointer selected` : `fw-bold make-pointer`}>Profile</Nav.Item>
            <Nav.Item onClick={() => setPage('party')} className={page === "party" ? `fw-bold make-pointer selected` : `fw-bold make-pointer`}>House Parties</Nav.Item>
        </div>
    );
}

export default DashboardNav;