import React from "react";
import Img from "react-cool-img";
import { Link } from "react-router-dom";

import moment from "moment";
import { Loader } from "../../../../helpers";
import { v4 } from "uuid";

import { useQuery } from "react-query";
import {
  fetchUpcomingPartiesAsHost,
  fetchGuestlist,
} from "../../../../../api/requests";

import DP from "../../../../../assets/DP.png";
import { GuestListCard } from "../GuestList";

export default function PartyGuestList(props) {
  const { data: parties, isLoading: isPartiesLoading } = useQuery(
    ["FETCH_PARTIES_AS_HOST", props.token],
    fetchUpcomingPartiesAsHost,
    {
      retry: 0,
      refetchOnWindowFocus: false
    }
  );

  if (isPartiesLoading) {
    return (
      <div className="loading-overlay">
        <Loader />
      </div>
    );
  }

  return (
    <GuestListCard nametag="House Parties" classes="party pt-5 pb-4 px-4">
      {parties && parties.data !== null && parties.data.length === 0 && (
        <>
          <p className="mt-5 text-primary text-center">No parties here!</p>
          <Link
            to="/host-party"
            className="mx-auto btn btn-outline-primary text-right fw-bold rounded-lg"
          >
            Start hosting now
          </Link>
        </>
      )}
      {parties.data.map((party) => {
        return (
          <React.Fragment key={v4()}>
            <PartyCard {...party} />
            <p className="fw-bold mt-4">Guest List:</p>
            <Guestlist event_id={party.event_id} token={props.token} />
          </React.Fragment>
        );
      })}
    </GuestListCard>
  );
}

function PartyCard(props) {
  return (
    <div className="d-flex justify-content-between mt-5">
      <div>
        <h5 className="fw-bold mb-2">{props.heading}</h5>
        <small className="text-muted d-block mb-1">
          {moment(props.date).format("MMM DD YYYY")} | {props.time_from} -
          {props.time_to}
        </small>
        <small className="text-muted d-block">
          {/* USE CITYNAME and LOCALITY */}
          {props.location && props.location.locality}, {props.location && props.location.location}
        </small>
      </div>
      <div>
        <Link
          to={`/parties/${props.event_id}`}
          className="text-primary text-uppercase"
        >
          Details
        </Link>
      </div>
    </div>
  );
}

function Guestlist(props) {
  const { data: guestlist, isLoading: isGuestlistLoading } = useQuery(
    ["FETCH_GUESTLIST", props.event_id, 1, props.token],
    fetchGuestlist, {
    refetchOnWindowFocus: false
  }
  );
  return (
    <div
      className={
        !isGuestlistLoading && guestlist.data.length !== 0 ? `guestbox` : null
      }
    >
      {!isGuestlistLoading && guestlist.data.length === 0 && (
        <p>No guests attending yet.</p>
      )}
      {!isGuestlistLoading &&
        guestlist.data.length !== 0 &&
        guestlist.data.map((guest) => {
          return (
            <div className="d-flex" key={v4()}>
              <Img
                src={guest.guest_image}
                error={DP}
                height="30"
                width="30"
                className="rounded-circle"
              />
              <p className="fw-bold ml-3">{guest.guest_name}</p>
            </div>
          );
        })}
    </div>
  );
}
