import React from "react";
import { Modal } from "react-bootstrap";

export default function UploadCover() {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title><h2>Host a party</h2></Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h4>Upload a cover image for your party</h4>
      </Modal.Body>
    </>
  );
}