import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Default, Mobile } from "../../helpers/Breakpoints";
import discoBall from "../../../assets/image 7.svg";
import HousePartyModal from "../HostAParty/HostPartyModal";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../modules/auth/Auth";

export default function HostPartyBanner({ diff, isShowHostRequestModel = false, setShowHostRequestModel }) {
  let [showLoginModal, setShowLoginModal] = useState(null);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleOpenModal = () => {
    if (!isAuthenticated) {
      setShowLoginModal(true);
    } else {
      setShowHostRequestModel(true);
    }
  };
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowLoginModal(false);
  };

  const handleLogin = () => {
    setShowHostRequestModel(true);
  };

  return (
    <section className="wholegbg_black hostParty_bannercont">
      <div className="padAd">
        <Container>
          <HousePartyModal
            show={isShowHostRequestModel}
            handleClose={() => {
              setShowHostRequestModel(false)
            }}
          />
          <Default>
            <Modal
              size="lg"
              show={showLoginModal}
              onHide={handleClose}
              backdrop="static"
              dialogClassName="modal-dimensions borderandWidth"
              centered
            >
              <Auth handleClose={handleClose} handleLogin={handleLogin} />
            </Modal>
          </Default>
          <Mobile>
            <Modal
              size="lg"
              show={showLoginModal}
              onHide={handleClose}
              backdrop="static"
              centered
            >
              <Auth handleClose={handleClose} handleLogin={handleLogin} />
            </Modal>
          </Mobile>
          <div className="middleBanner">
            <img src={discoBall} alt="" className="discoBall" />
            <p>
              {diff ? diff : "Host more, earn more! Keep the momentum going."}
            </p>

            <button
              className="btn btn-reg btn-primary customizeBtn"
              onClick={handleOpenModal}
            >
              Let’s Host A Party
            </button>
          </div>
        </Container>
      </div>
    </section>
  );
}
