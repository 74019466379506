import React, { useState, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector, connect } from "react-redux";
import "./OTPComponent.module.scss";
import useForm from "../../../utils/useForm";
import { getErrorMessage, regex } from "../../../utils/utils";

function OTPComponent(props) {
  const initialValues = {
    otp: {
      value: "",
      patternErrorMessage: "OTP is not valid",
      regex: regex.otp,
      isRequired: true,
      isErrored: false,
      errorMessage: "",
    },
  };

  const { form, handleFormChange, handleSubmit } = useForm(initialValues);

  const dispatch = useDispatch();

  //   console.log(props);

  return (
    <div>
      <form
        onSubmit={(e) => {
          handleSubmit(e, () => {
            props.handleHost(form.otp.value);
          });
        }}
        className="btnFam"
      >
        <div className="twerky mt-5">
          <label>OTP Verification</label>
          <p className="">Enter the OTP sent to {props.phone}</p>
        </div>

        <div
          style={{
            display: "flex",
            marginBottom: "1rem",
          }}
          className="inputLoginOtp"
        >
          <input
            style={{ width: "auto" }}
            type="number"
            id="otp"
            name="otp"
            placeholder="Enter OTP"
            autoFocus
            value={form.otp.value}
            onChange={handleFormChange}
          />
        </div>

        {getErrorMessage(form, "otp")}

        <Button
          type="submit"
          variant="primary"
          block
          className="mt-3 py-2"
          // onClick={() => { props.handleHost(Object.values(otp).join("")) }}
          // disabled={Object.values(otp).join("").length < 6}
        >
          Verify OTP
        </Button>
        {/* <p
          className="mt-3 py-2 text-center text-primary make-pointer"
          onClick={() => {
            setOtp({
              digit_1: "",
              digit_2: "",
              digit_3: "",
              digit_4: "",
              digit_5: "",
              digit_6: "",
            });
            setOTPVerified(false);
          }}
        >
          Clear
        </p> */}
      </form>
    </div>
  );

  // return (
  //   <div>
  //     <Form onSubmit={handleSubmit} className="btnFam">
  //       <div className="twerky mt-5">
  //         <label>OTP Verification</label>
  //         <p className="">Enter the OTP sent to {props.phone}</p>
  //       </div>

  //       <div
  //         style={{
  //           display: "flex",
  //           marginBottom: "2rem",
  //         }}
  //         className="inputLoginOtp"
  //       >
  //         <input
  //           ref={digit_1}
  //           type="number"
  //           name="digit_1"
  //           className="otp-field"
  //           value={otp.digit_1}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               digit_2.current.focus();
  //             }
  //           }}
  //           autoFocus
  //         />
  //         <input
  //           ref={digit_2}
  //           type="number"
  //           name="digit_2"
  //           className="otp-field"
  //           value={otp.digit_2}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               digit_3.current.focus();
  //             }
  //           }}
  //         />
  //         <input
  //           ref={digit_3}
  //           type="number"
  //           name="digit_3"
  //           className="otp-field"
  //           value={otp.digit_3}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               digit_4.current.focus();
  //             }
  //           }}
  //         />
  //         <input
  //           ref={digit_4}
  //           type="number"
  //           name="digit_4"
  //           className="otp-field"
  //           value={otp.digit_4}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               digit_5.current.focus();
  //             }
  //           }}
  //         />
  //         <input
  //           ref={digit_5}
  //           type="number"
  //           name="digit_5"
  //           className="otp-field"
  //           value={otp.digit_5}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               digit_6.current.focus();
  //             }
  //           }}
  //         />
  //         <input
  //           ref={digit_6}
  //           type="number"
  //           name="digit_6"
  //           className="otp-field"
  //           value={otp.digit_6}
  //           onChange={(e) => {
  //             logChange(e);
  //             if (e.target.value.length > 0) {
  //               submitRef.current.focus();
  //             }
  //           }}
  //         />
  //       </div>

  //       <Button
  //         type="submit"
  //         ref={submitRef}
  //         variant="primary"
  //         block
  //         className="mt-3 py-2"
  //         // onClick={() => { props.handleHost(Object.values(otp).join("")) }}
  //         disabled={Object.values(otp).join("").length < 6}
  //       >
  //         Verify OTP
  //       </Button>
  //       {OTPError !== "" ? (
  //         <p className="mt-3 py-2 text-center text-primary">{OTPError}</p>
  //       ) : null}
  //       {/* <p
  //         className="mt-3 py-2 text-center text-primary make-pointer"
  //         onClick={() => {
  //           setOtp({
  //             digit_1: "",
  //             digit_2: "",
  //             digit_3: "",
  //             digit_4: "",
  //             digit_5: "",
  //             digit_6: "",
  //           });
  //           setOTPVerified(false);
  //         }}
  //       >
  //         Clear
  //       </p> */}
  //     </Form>
  //   </div>
  // );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(OTPComponent);
