import toast from "react-hot-toast";
import Lottie from "react-lottie";
import animationData from "./loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const showSuccessToast = (message) => {
  toast.success(message);
};

export const showErrorToast = (message) => {
  toast.error(message);
};

export const getErrorMessage = (form, key) => {
  if (form[key]?.isErrored) {
    return <p style={{
      color: "red",
      fontFamily: "Kumbh Sans",
      fontSize: "12px"
    }}>{form[key].errorMessage}</p>;
  }
};

export const formatTime = (time) => {
  if (!time) return ""; // Return an empty string if time is undefined

  let [hour, minute] = time.split(":").map(Number);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  return `${hour}:${minute < 10 ? "0" + minute : minute} ${ampm}`;
};

export const showStarRating = (value) => {
  return (
    <div className="rating-container">
      {[1, 2, 3, 4, 5].map((star) => (
        <span key={star} className={`star ${value >= star ? "filled" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="icon icon-tabler icons-tabler-filled icon-tabler-star"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z" />
          </svg>
        </span>
      ))}
    </div>
  );
};

export const getLoader = () => {
  return (
    <div className="fullscreen-loader">
      {/* <div className="loader"></div> */}
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  );
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const regex = {
  phone: "^[6-9]\\d{9}$",
  email: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
  name: "^[a-zA-Z\\s'-]+$",
  otp: "^[0-9]{6}$",
}

export const checkIsWebView = () => {
  //iOS
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.ReactNativeWebView) {
    return {
      isWebView: true,
      messageFunction: window.webkit.messageHandlers.ReactNativeWebView
    }
  }
  // Android
  if (window.ReactNativeWebView) {
    return {
      isWebView: true,
      messageFunction: window.ReactNativeWebView
    }
  }

  return {
    isWebView: false,
    messageFunction: () => { }
  }

}