import React, { useEffect, useState } from "react";
import { Default, Mobile } from "../helpers/Breakpoints";
import { Container, Row, Col, Button } from "react-bootstrap";
import { generateReferralLink } from "../../api/requests";
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import RefImg from "../assets/img/get-discounts.jpeg";
import Refer from "../assets/img/invite-friends.jpeg";
import FollowAlong from "../assets/img/friend-installs-attends.jpeg";
import heroImg from "../assets/Group 207 Copy.svg";

export default function Referral() {

  ReactGA.event({
    category: 'User',
    action: 'Clicked on referrals'
  });

  let [shortLink, setshortLink] = useState("");
  //   let [twitter, setTwitter] = useState();

  useEffect(() => {
    getShortLink();
  }, []);

  const getShortLink = async () => {
    const link = await generateReferralLink();
    setshortLink(link);
  };

  return (
    <>
      <Default>
        <Container style={{ margin: "7rem auto 0 auto" }}>
          <Row>
            <Col md={6} className="my-auto">
              <h2>Invite your friends and be a friend, with benefits!</h2>
              <p>Get &#x20B9;150 off on your next party. <br /> Gift your friend &#x20B9;150 off on their first.</p>
              <input type="text" defaultValue={shortLink} />
              <div className="d-flex justify-content-between align-items-center">
                <Button
                  onClick={() => getShortLink()}
                  variant="primary"
                  size="md"
                >
                  Get new link
                </Button>
                <h4 className="m-0">OR</h4>

                <div className="d-flex align-items-center">
                  <p className="d-inline-block m-0">Share to:</p>
                  {shortLink && (
                    <>
                      <FontAwesomeIcon
                        icon={faFacebook}
                        size="2x"
                        className="text-primary mx-3 make-pointer"
                        onClick={() => {
                          let url = `https://www.facebook.com/sharer.php?u=${shortLink}`;
                          window.open(
                            url,
                            "Facebook",
                            "resizable,height=460,width=770"
                          );
                          return false;
                        }}
                      ></FontAwesomeIcon>
                      <FontAwesomeIcon
                        icon={faTwitter}
                        size="2x"
                        className="text-primary mx-3 make-pointer"
                        onClick={() => {
                          let url = `https://twitter.com/intent/tweet?url=${shortLink}&text=Come join me for a party with Playace!&hashtags=party,houseparty,playace`;
                          window.open(
                            url,
                            "twitter",
                            "resizable,height=460,width=770"
                          );
                          return false;
                        }}
                      ></FontAwesomeIcon>

                      {/* <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-primary mx-3 make-pointer"
                  ></FontAwesomeIcon> */}
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="text-right">
                <img className="img-fluid" src={heroImg} alt="Referrals" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="bg-gray p-5 mt-5">
          <Row className="text-center">
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center text-left"
            >
              <img style={{ width: "40%" }} src={Refer} alt="refer" />
              {/* <div className="mx-3">
                <h4 className="fw-bold text-primary">Invite friends</h4>
              </div> */}
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center text-left"
            >
              <img
                style={{ width: "40%", backgroundBlendMode: "multiply" }}
                src={FollowAlong}
                alt="follow along"
              />
              {/* <div className="mx-3">
                <h4 className="fw-bold text-secondary">Friend installs and attends a party</h4>
              </div> */}
            </Col>
            <Col
              md={4}
              className="d-flex justify-content-center align-items-center text-left"
            >
              <img style={{ width: "40%" }} src={RefImg} alt="get paid" />
              {/* <div className="mx-3">
                <h4 className="fw-bold text-primary">Get discounts</h4>
              </div> */}
            </Col>
          </Row>
        </Container>
      </Default>
      <Mobile>
        <div style={{ margin: "6rem auto 0 auto", padding: "0 1rem" }}>
          <img
            className="img-fluid w-75 d-block mx-auto"
            src={heroImg}
            alt="Refer to friends"
          />
          <h3 className="mt-5">
            Invite your friends and be a friend, with benefits!
          </h3>
          <p>Get &#x20B9;150 off on your next party. <br /> Gift your friend &#x20B9;150 off on their first.</p>
          <div className="my-5"></div>
          <input type="text" defaultValue={shortLink} />
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <Button
              onClick={() => getShortLink()}
              variant="primary"
              size="md"
            >
              Get new link
                </Button>
            <h4 className="m-0">OR</h4>

            <div className="d-flex align-items-center">
              <p className="d-inline-block m-0">Share to:</p>
              {shortLink && (
                <>
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="2x"
                    className="text-primary mx-3 make-pointer"
                    onClick={() => {
                      let url = `https://www.facebook.com/sharer.php?u=${shortLink}`;
                      window.open(
                        url,
                        "Facebook",
                        "resizable,height=460,width=770"
                      );
                      return false;
                    }}
                  ></FontAwesomeIcon>
                  <FontAwesomeIcon
                    icon={faTwitter}
                    size="2x"
                    className="text-primary mx-3 make-pointer"
                    onClick={() => {
                      let url = `https://twitter.com/intent/tweet?url=${shortLink}&text=Come join me for a party with Playace!&hashtags=party,houseparty,playace`;
                      window.open(
                        url,
                        "twitter",
                        "resizable,height=460,width=770"
                      );
                      return false;
                    }}
                  ></FontAwesomeIcon>

                  {/* <FontAwesomeIcon
                    icon={faInstagram}
                    size="2x"
                    className="text-primary mx-3 make-pointer"
                  ></FontAwesomeIcon> */}
                </>
              )}
            </div>
          </div>
          <Container fluid className="bg-gray p-5 mt-5">
            <Row className="text-center">
              <Col
                sm={12}
                className="d-flex justify-content-center align-items-center mt-5"
              >
                <img /*style={{ width: "45%" }}*/ className="img-fluid" src={Refer} alt="refer" />
                {/* <h4 className="fw-bold text-primary">Invite friends</h4> */}
              </Col>
              <Col
                sm={12}
                className="d-flex justify-content-center align-items-center mt-5"
              >
                <img
                  /*style={{ width: "35%", backgroundBlendMode: "multiply" }}*/
                  src={FollowAlong}
                  alt="follow along"
                  className="img-fluid"
                />
                {/* <h4 className="fw-bold text-right text-secondary">Friend installs and attends a party</h4> */}
              </Col>
              <Col
                sm={12}
                className="d-flex justify-content-center align-items-center mt-5"
              >
                <img /*style={{ width: "30%" }}*/ className="img-fluid" src={RefImg} alt="get paid" />
                {/* <h4 className="fw-bold text-primary text-right">Get discounts</h4> */}
              </Col>
            </Row>
          </Container>
        </div>
      </Mobile>
    </>
  );
}
