import React from 'react';
import '../../assets/index.scss';

export default function Skeleton() {
    return (
        <div className="m-3 grid-item">
            <div className="skeleton skeleton-img"></div>
            <div className="my-3 skeleton skeleton-txt"></div>
            <div className="skeleton skeleton-txt"></div>
        </div>
    );
}