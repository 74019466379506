import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import { RenderUserObj } from "../../helpers";
import styles from "./PlanningOverview.module.scss";
import { GoogleApiWrapper } from "google-maps-react";
import ThankyouImage from "../../../assets/request_sent.png";

import { GoogleMapsAPI } from "./client-config";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import {
  createEventRequest,
  fetchNewCities,
} from "../../../api/requests";
import Select from "react-select";

function PlanningOverview(props) {
  const state = useSelector((state) => state.hostParty);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [date, setDate] = useState("");
  const [cities, setCities] = useState([]);
  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const history = useHistory();
  const userObj = RenderUserObj(useSelector((state) => state.auth.data));

  useEffect(() => {
    fetchNewCities(userObj.token).then((response) => {
      const dropdownOptions = response?.data?.map((option) => ({
        label: option,
        value: option,
      }));
      setCities(dropdownOptions);
      setLoading(false)
    });
  }, [userObj.token]);

  const { handleSubmit, errors, control, register, clearErrors } = useForm({
    defaultValues: {
      name: state.data.name,
      description: state.data.description,
      party_date: state.data.party_date,
      start_time: state.data.start_time,
      end_time: state.data.end_time,
      last_date: state.data.last_date,
      accommodate_people: parseInt(state.data.accommodate_people),
      price: parseInt(state.data.price),
      state_id: state.data.selectedState,
      city_id: state.data.city,
      locality: state.data.locality,
      address: state.data.address,
      pincode: state.data.pincode,
    },
  });

  const onSubmit = (e) => {
    setLoading(true);
    clearErrors(["search"]);
    let partyRequest = {
      phone: userObj.phone,
      address: address,
      eventDate: moment(date).format("yyyy-MM-DD"),
      city: city,
      pincode: parseInt(pincode),
    };
    createEventRequest(userObj.token, partyRequest).then((response) => {
      setLoading(false);

      if (response.status === "success") {
        toast.success("Event Sent For Approval!");
        clearErrors(["search"]);
        setShowRequestSentModal(true);
        try {
          props.closeModal();
        } catch (error) { }
      } else {
        toast.error(response.message ?? "Something went wrong! Try Again Later.");
      }
    });
  };

  const handlePincode = (e) => setPincode(e.target.value);
  const handleAddress = (e) => setAddress(e.target.value);
  const handleCity = (e) => setCity(e.value);

  const handleCloseRequestSentModal = () => {
    setShowRequestSentModal(false);
    history.push("/");
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      <Container className="pb-md-5 pt-2" style={{ marginTop: "90px" }}>
        <h2 className="text-center">Planning overview</h2>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col
                md={12}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <Col md={12}>
                  <p className={`${styles["message"]}`}>
                    Bookings close 1 hour prior to the party start time
                  </p>
                  <div className="d-flex flex-column position-relative">
                    <label>
                      <h4>Tentative date of the House Party</h4>
                    </label>
                    <Controller
                      className="col-12 w-100"
                      control={control}
                      name="birthDate"
                      rules={{ required: true }}
                      defaultValue={null}
                      render={(props) => (
                        <DatePicker
                          className="input"
                          selected={props.value}
                          minDate={new Date()}
                          onChange={(e) => {
                            props.onChange(e);
                            setDate(e);
                          }}
                          placeholderText="dd/mm/yyyy"
                        />
                      )}
                    />
                    <p className="text-danger">
                      {errors.birthDate && "Required"}
                    </p>
                  </div>
                  <div>
                    <label>
                      <h5>Venue Address</h5>
                    </label>
                    <input
                      name="currentAddress"
                      type="text"
                      onChange={handleAddress}
                      placeholder="Venue Address"
                      value={address}
                    />
                  </div>
                  <div></div>
                  <label>
                    <h5>City</h5>
                  </label>
                  <Select
                    id="city_id"
                    name="city"
                    defaultValue={city}
                    onChange={(e) => {
                      handleCity(e);
                    }}
                    options={cities}
                  // ref={register({
                  //   required: "Required",
                  // })}
                  />
                  <p className="text-danger">
                    {errors.city_id && errors.city_id.message}
                  </p>

                  <label>
                    <h5>Pincode</h5>
                  </label>
                  <input
                    name="pincode"
                    type="text"
                    onChange={handlePincode}
                    placeholder="Pincode"
                    value={pincode}
                  />
                  {pincode && city !== "" ? (
                    <Button
                      type="submit"
                      className={`${styles["submit"]} btn btn-primary mt-3 py-2 w-50`}
                    >
                      Submit
                    </Button>
                  ) : <Button
                    type="submit"
                    disabled
                    className={`${styles["submit"]} btn btn-primary mt-3 py-2 w-50`}
                  >
                    Submit
                  </Button>}
                </Col>
              </Col>
            </Row>
          </form>
        </Container>
      </Container>
      <Modal
        show={showRequestSentModal}
        backdrop="static"
        onHide={handleCloseRequestSentModal}
      >
        <div
          className={"d-flex flex-column justify-content-center text-center"}
        >
          <img src={ThankyouImage} alt="Request-sent-image" />
          <h5>Party Request sent</h5>
          <p>
            Your hosting request is being reviewed.
            <br />
            Our party experts will get in touch with you shortly!
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary text-uppercase fw-bold px-4 mt-2 mb-3 mx-auto"
              type="submit"
              onClick={handleCloseRequestSentModal}
            >
              Okay
            </button>
          </div>
        </div>
      </Modal>
    </LoadingOverlay>
  );
}

export default GoogleApiWrapper({
  apiKey: GoogleMapsAPI,
})(PlanningOverview);
