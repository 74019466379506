import React from "react";
import { Row, Col } from "react-bootstrap";
// import axios from "axios";
// import { URL } from "../../../../api/requests";

import { useSelector } from "react-redux";
import { Default, Mobile } from "../../../helpers/Breakpoints";
// import { Loader } from "../../../helpers";

import PartyGuestList from "./Parties/PartyGuestList";
import SpaceGuestList from "./Spaces/SpaceGuestList";
import "./index.scss";

function GuestList() {

  const auth = JSON.parse(useSelector((state) => state.auth.data));

  return (
    <>
      <Default>
        <Row>
          <Col md={6} sm={12}>
            <PartyGuestList token={auth.token} />
          </Col>
          <Col md={6} sm={12}>
            <SpaceGuestList token={auth.token} />
          </Col>
        </Row>
      </Default>
      <Mobile>
        <Row>
          <Col sm={12} className="mx-4 mb-4">
            <PartyGuestList token={auth.token} />
          </Col>
          <Col sm={12} className="mx-4">
            <SpaceGuestList token={auth.token} />
          </Col>
        </Row>
      </Mobile>
    </>
  );
}

export function GuestListCard({ children, ...props }) {
  return (
    <div className={`shadow rounded-lg bg-white guest-card-${props.classes}`}>
      <div
        className={`nametag ${
          props.nametag === "House Parties" ? `bg-primary` : `bg-secondary`
        }`}
      >
        <p className="m-0 text-uppercase fw-bold float-right">
          {props.nametag}
        </p>
      </div>
      {children}
    </div>
  );
}

export default GuestList;
