import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function PayOutHome() {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const { register, handleSubmit } = useForm();

  const handleSubmitApiCall = (data) => {
    console.log("data", data);
  };

  return (
    <section style={{ marginTop: "7rem" }}>
      <div className="container">
        <div className="payoutHero">
          <h1>Payouts</h1>

          <h3>Add Payment Method</h3>
          <p>Add & manage your payment method to receive party earnings</p>

          <div className="listOfBanks">
            <div className="singleBank">
              <div className="bankIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon icon-tabler icons-tabler-outline icon-tabler-building-bank"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 21l18 0" />
                  <path d="M3 10l18 0" />
                  <path d="M5 6l7 -3l7 3" />
                  <path d="M4 10l0 11" />
                  <path d="M20 10l0 11" />
                  <path d="M8 14l0 3" />
                  <path d="M12 14l0 3" />
                  <path d="M16 14l0 3" />
                </svg>
              </div>

              <div className="bankDetails_cont">
                <h5>Bank Name - HDFC Bank</h5>
                <p>1-307-703-2909 x2065</p>
              </div>
            </div>
          </div>

          <Button
            type="button"
            className="btn btn-primary mt-3 py-2"
            onClick={handleShow}
          >
            Add Bank Account
          </Button>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="payOutForm" onSubmit={handleSubmit(handleSubmitApiCall)}>
            <div className="mb-3">
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                id="bankName"
                className="form-control"
                placeholder="Bank Name"
                {...register("bankName")}
              />
            </div>

            {/* <div className="mb-3">
              <label htmlFor="accountNumber">Account Number</label>
              <input
                type="text"
                id="accountNumber"
                className="form-control"
                placeholder="Account Number"
                {...register("accountNumber", { required: true })}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="confirmAccountNumber">Confirm Account Number</label>
              <input
                type="text"
                id="confirmAccountNumber"
                className="form-control"
                placeholder="Confirm Account Number"
                {...register("confirmAccountNumber", { required: true })}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="ifscCode">IFSC Code</label>
              <input
                type="text"
                id="ifscCode"
                className="form-control"
                placeholder="IFSC Code"
                {...register("ifscCode", { required: true })}
              />
            </div> */}

            <Button type="submit" className="btn btn-primary mt-3 w-100">
              Save Payment Method
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </section>
  );
}
