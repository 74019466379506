import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Default } from "../../helpers/Breakpoints";
import Expander from "../../modules/content/EventDetails/Expander";
import ContactUsModal from "./ContactUsModal";

import contactBg from "../../../assets/Frame 663.png";

const faqs_guest = [
  {
    title: "How do I attend a House Party?",
    desc: "Log on to playace.co, select the party you wish to attend, and send your request to book by processing the payment. You will receive further steps via WhatsApp and your registered mobile number. If you are attending for the first time, upload your government ID through your profile. One of our team members will call you once you complete this process. After this call, you will receive the status of your booking request within a few minutes to a few hours.",
  },
  {
    title: "Why should I pay first?",
    desc: "You should pay first in order to place your request to attend the party. This helps us confirm your intent to attend and enables us to block your spot on priority, provided you are a verified user and there is a spot available for your gender.",
  },
  {
    title: "How will I know if my request is approved or disapproved?",
    desc: "If your request is approved, you will receive the details of the venue and the host via WhatsApp and your registered email address. If your request is disapproved, your refund will be initiated immediately. You will receive your amount within 5-7 working days, depending on the bank you transact with.",
  },
  {
    title: "On what basis are guests approved?",
    desc: "Guests are approved based on verifications, how they conduct themselves during the tele-verification, and if their request can be accepted based on the gender ratio balance.",
  },
  {
    title: "On what basis are guests disapproved?",
    desc: `If your request is disapproved, please don't get disappointed. The following could be the reasons: overwhelming requests, limited spots, incomplete verification, gender ratio imbalance, or if your profile is reported or barred.`,
  },
];

const faqs_host = [
  {
    title: "How can I host a party?",
    desc: "Send your request to host a house party by finding the option on our website playace.co on the home page. You can also DM us on our Instagram handle @playaceapp",
  },
  {
    title: "How much can I earn by hosting?",
    desc: "As a regular host, you can earn anywhere between Rs. 15,000 to Rs. 40,000 a month on average",
  },
  {
    title: "How will I know who is attending my house party?",
    desc: "Once your party is live, you will start receiving requests from guests. All guests will be verified with multiple layers of verification. Only when the guests are approved will they receive your address and contact details.",
  },
  {
    title: "When and how will I receive the hosting commission?",
    desc: "On the day of the house party, an assigned team member will be present at the venue to help you curate and ensure the safety and standard of the experience. After the successful completion of the house party, you will receive your payout within a week.",
  },
];

export default function Faq({ isGuest = true }) {
  const [modalShow, setModalShow] = useState(false);
  const faqs = isGuest ? faqs_guest : faqs_host;
  return (
    <section className="wholegbg_black faqCont">
      <Container>
        <div className="homefaqgrid">
          <div className="leftSide">
            <div className="topText">
              <p>The Playace Playbook: All you need to know!</p>
            </div>

            <div className="centimg">
              <img src={contactBg} alt="" />
            </div>

            {/* <div className="flexBtn_cont">
                <p>Still have questions?</p>

                <button
                  className="outlineBtn"
                  onClick={() => setModalShow(true)}
                >
                  Contact Us
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-up-right"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M17 7l-10 10" />
                    <path d="M8 7l9 0l0 9" />
                  </svg>
                </button>
              </div> */}
          </div>

          <div className="rightSide_faq">
            {faqs?.map((faq, index) => {
              return (
                <div key={index.toString()}>
                  <Expander title={faq.title} data={faq.desc} />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      <ContactUsModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
      />
    </section>
  );
}
