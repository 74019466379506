import React from "react";

export default function NavTab({ Tab, setTab }) {
  return (
    <div>
      <div className="d-flex justify-content-center">
        <h4
          className={`make-pointer mr-2 ${
            Tab === "parties" ? "text-primary border-bottom border-primary" : null
          }`}
          onClick={() => setTab("parties")}
        >
          House Parties
        </h4>
        <h4
          className={`make-pointer ${
            Tab === "spaces" ? "text-primary border-bottom border-primary" : null
          }`}
          onClick={() => {
            setTab("spaces");
          }}
        >
          Space Listings
        </h4>
      </div>
    </div>
  );
}
