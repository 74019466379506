import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollContainer from "react-indiana-drag-scroll";
import { EventCard } from "../../modules/content";
import { useSelector } from "react-redux";
import { Skeleton } from "../../modules/common";
import Slider from "react-slick";
import { Desktop, Mobile } from "../../helpers/Breakpoints";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function HostedParties(props) {
  let [slideShow, setSlideShow] = useState(null);

  var settings = {
    dots: false,
    infinite: slideShow > 3 ? true : false,
    speed: 500,
    slidesToShow: slideShow,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow className="customArrow" />,
    prevArrow: <SamplePrevArrow className="customArrow" />,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let [token, setToken] = useState(null);
  const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const isShow = true;

  let userObj = useSelector((state) => state?.auth?.data);
  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const authState = useSelector((state) => state?.auth);
  useEffect(() => {
    if (props?.data?.pastEvents?.length === 1) {
      setSlideShow(1);
    }
    if (props?.data?.pastEvents?.length === 2) {
      setSlideShow(2);
    }
    if (props?.data?.pastEvents?.length >= 3) {
      setSlideShow(3);
    }

    if (isAuthenticated) {
      if (userObj !== undefined) setToken(userObj?.token);
    }
  }, [props, isAuthenticated, userObj]);

  return (
    <section className="arrrowsCont">
      <h2 className="partiesHostedTit">PARTIES HOSTED</h2>
      {/* <ScrollContainer
        className="scroll-container"
        horizontal={true}
        hideScrollbars={false} // Show native scrollbars
        style={{ overflowY: 'hidden' }} // Hide vertical scrollbar
      >
        <Row style={{ flexWrap: 'nowrap' }}>
          {props?.data === undefined
            ? rand.map((item, index) => {
              return <Skeleton key={index} />;
            })
            : props?.data.upcomingEvents?.map((item, index) => {
              return (
                <Col md={6} sm={12} xs={12} lg={6} key={index}>
                  <EventCard
                    classes="mb-3"
                    type="party"
                    {...item}
                    additionalData={isShow}
                  />
                </Col>
              );
            })}
        </Row>
      </ScrollContainer> */}

      <>
        <Desktop>
          {props?.data.pastEvents?.length < 4 ? (
            <ScrollContainer
              className="scroll-container"
              horizontal={true}
              hideScrollbars={false} // Show native scrollbars
              style={{ overflowY: "hidden" }} // Hide vertical scrollbar
            >
              <div className="d-flex">
                {props?.data.pastEvents?.map((item, index) => {
                  return (
                    <EventCard
                      classes="mb-3 maxfitCard"
                      // type="party"
                      {...item}
                      additionalData={isShow}
                    />
                  );
                })}
              </div>
            </ScrollContainer>
          ) : (
            <Slider {...settings}>
              {props?.data.pastEvents?.map((item, index) => {
                return (
                  <EventCard
                    classes="mb-3 maxfitCard"
                    // type="party"
                    {...item}
                    additionalData={isShow}
                  />
                );
              })}
            </Slider>
          )}
        </Desktop>

        <Mobile>
          <Slider {...settings}>
            {props?.data.pastEvents?.map((item, index) => {
              return (
                <EventCard
                  classes="mb-3 maxfitCard"
                  // type="party"
                  {...item}
                  additionalData={isShow}
                />
              );
            })}
          </Slider>
        </Mobile>
      </>
    </section>
  );
}

export default HostedParties;
