import React from "react";
import { Container } from "react-bootstrap";

export default function SpaceListing() {
  return (
    <Container>
      <h4 className="text-center maintopTit">Refund and Cancellation Policy</h4>
      <div>
        <p>
          Playace designed the Platform and Services to help Hosts and Guests
          efficiently and conveniently complete Bookings. Every Booking is a
          Host’s commitment to make a Space available to a Guest who has paid in
          full upfront upon confirmation. When Guests cancel Bookings, the
          Host’s ability to Book that Space during that time becomes
          increasingly difficult as other interested Guests may have already
          made other plans. When Hosts cancel Bookings, Guests’ events and plans
          can be severely impacted and may require intensive logistical support
          from Playace. Nonetheless, plans may change, requiring Guests or Hosts
          to cancel Bookings on occasion. This Playace Cancellation and Refund
          Policy ("Policy") covers all cancellations or refunds for Fees paid on
          the Platform, which are identified in the Fees Overview. Both Guests
          and Hosts agree that they are subject to and will comply with the
          terms, conditions, and procedures of this Policy when cancelling a
          Booking. This Policy is part of and uses words and phrases defined in
          the the Playace Services Agreement. If you have any questions about
          this Policy, please contact us.
        </p>
        <p>
          You must agree to this Policy, as part of the Playace Services
          Agreement, to use the Services and the Platform. Please read this
          Policy carefully. You may not use the Platform or Services if you do
          not agree to this Policy.
        </p>
        <p>
          Playace may issue payments to Hosts, withhold refunds to Guests, or
          collect cancellation Fees from Hosts in accordance with this Policy.
          We reserve the right to amend this Policy for any reason and at any
          time by posting an updated version to our website as provided in the
          Playace Services Agreement.
        </p>
        <h5 className="my-4">Guest initiated cancellations</h5>
        <p>
          Guests may cancel Bookings up to 48 hours after the Booking is
          confirmed by the Host and receive a full refund, provided the
          cancellation is made at least 1 week (168 hours) before the scheduled
          start time of the Booking (“Start Time”). All cancellations must be
          submitted through the Platform and not by email. Bookings made one
          week or less in advance of the Start Time or cancellations made more
          than 48 hours after confirmation of the Booking will incur a
          cancellation charge that includes the Processing Fee and a proportion
          of the price paid for the Booking (collectively a “Cancellation Fee”)
          as follows:
        </p>
        <p>
          Refunds to Guests will be applied after the cancellation is finalized.
          Payouts to Hosts will be made within 7 days following the Start Time
          of the cancelled booking. If a Host receives a new Booking for the
          Space at the same time as the cancelled Booking, Playace may elect to
          return all or a portion of the payout otherwise payable to the Host as
          part of the cancellation back to the Guest.
        </p>
        <p>
          Where Guests cancel a portion of a multi-day Booking, the Fees and
          refunds will only apply to the specific dates cancelled. Guests may be
          able to update a Booking time without penalty where a Host agrees to
          the new timing.
        </p>
        <p>
          Cancellation Date Guest Refund Host Payout 30+ calendar days prior to
          Booking start time 75%, minus Processing Fee 25%, minus Payment
          Processing Fee 29-18 calendar days prior to Booking start time 50%,
          minus Processing Fee 50%, minus Payment Processing Fee 17-8 calendar
          days prior to Booking start time 25%, minus Processing Fee 75%, minus
          Payment Processing Fee 7 Calendar days or less prior to Booking start
          time Nonrefundable 100%, minus Payment Processing Fees.
        </p>
        <h5 className="my-4">Host or vendor initiated cancellations</h5>
        <p>
          Playace may issue Fines on Hosts or Vendors for cancellations and may
          (i) deduct or debit any Fines from amounts that would otherwise be
          payable, or (ii) invoice such Fines to be paid within seven (7) days.
          Any Host- or Vendor-initiated cancellations will be fully refunded to
          the Guest (including any Fees paid). The Host or Vendor cancelling the
          Booking will be responsible for tangible costs incurred by Playace or
          the Guest associated with any cancellation, and any scheduled payouts
          for that Booking will be cancelled.
        </p>
        <h5 className="my-4">Fines for host initiated cancellations</h5>
        <p>
          Confirmed Bookings that are cancelled in whole or part by a Host are
          subject to the following:
        </p>
        <ul>
          <li>
            A Host may cancel 1 confirmed Booking within a 6-month period
            without penalty excluding those costs incurred by Playace or the
            Guest associated with that cancellation;
          </li>
          <li>
            Additional cancelled Bookings will result in a Fine of the greater
            of Rs. 5000 or 15% of the total Booking cost. This Fine may be
            either charged to a payment method linked to your account or
            deducted or debited from any amounts otherwise payable to you; and
          </li>
          <li>
            Cancellations of 3 or more bookings within a 6-month period may
            result in delisting or modifying the description of your Space to
            include publicly available information about previous cancellations,
            or suspension or termination of your Account.
          </li>
          <li>
            Hosts that have earned “Power Host” designation will forfeit that
            designation and any associated benefits after 1 cancellation for no
            less than a 6-month period.
          </li>
        </ul>
        <p>
          In our discretion, we may waive Fines for Host-initiated cancellations
          under the following circumstances:
        </p>
        <ul>
          <li>
            Accidental acceptance of a Booking cancelled within 2 hours of
            confirmation;
          </li>
          <li>
            Guests providing false or misleading information in Booking request;
          </li>
          <li>
            Guests suggesting their use of a Space in a manner inconsistent with
            the Description, the Booking Agreement, or other limitations agreed
            upon between the Guest and Host;
          </li>
          <li>
            Inappropriate or illegal activity taking place during a Booking;
          </li>
          <li>
            A Host and Guest mutually deciding to cancel the Booking with a full
            refund of the Booking cost to the Guest; or Any excused
            cancellations.
          </li>
        </ul>
        <h5 className="my-4">Excused Cancellations</h5>
        <p>
          In rare instances, it may be necessary to cancel a confirmed Booking
          due to extenuating circumstances under short notice for one of the
          situations identified below (“Excused Cancellation”):
        </p>
        <ul>
          <li>
            Unexpected death or serious illness of a Host, a Guest, or immediate
            family member of either;
          </li>
          <li>
            Serious injury that directly restricts a Guest’s ability to travel
            or a Host’s ability to provide the Booked Space;
          </li>
          <li>
            Significant natural disasters or severe weather incidents triggering
            a state of emergency that directly impact use of a Space or the
            Guest’s ability to travel to the Space;
          </li>
          <li>
            Urgent travel restrictions or severe security advisories issued
            after the time of Booking, by an appropriate government office or
            agency;
          </li>
          <li>
            Severe property damage or unforeseen maintenance issues that
            directly impact the safe use of the Space; or
          </li>
          <li>
            Legal, municipality, or utility injunction or order that directly
            restricts use of or access to a Space. Playace may require Guests or
            Hosts to provide evidence to support the Excused Cancellation. Where
            approved, the Booking Fees will be refunded in full to the Guest and
            no payout will be sent to the Host without Cancellation Fees or
            Fines.
          </li>
        </ul>
        <h5 className="my-4">Playaace initiated cancellations</h5>
        <p>
          Playace may decide, in its sole discretion, that it is necessary or
          desirable to cancel a confirmed Booking and issue a full or partial
          refund to a Guest for:
        </p>
        <ul>
          <li>An Excused Cancellation;</li>
          <li>
            The removal of a Host or Space from the Platform prior to the Start
            Time;
          </li>
          <li>Any actual or potential illegal or unauthorized activity;</li>
          <li>Risk of harm or safety concerns;</li>
          <li>
            Failure to provide proof of insurance, including event or film
            production insurance, when requested by Playace;
          </li>
          <li>Any violations the Community Guidelines; or</li>
          <li>Any other reason.</li>
        </ul>
        <p>
          Where Playace initiates a cancellation, any refunds or payouts will
          vary depending on the circumstances that prompted the cancellation.
          You understand and agree that neither Playace nor any of the other
          parties to the canceled Booking shall have any liability or obligation
          for any such Playace-initiated cancellations.
        </p>
        <h5 className="my-4">Cancellation of vendor services</h5>
        <p>
          Fees for cancellation of Vendor Services vary based on the Vendor and
          are provided at the time the request for Vendor Services are Booked.
          If a cancellation policy is not provided for your Vendor Services, the
          Cancellation Fees for Spaces listed above will apply, depending on
          whether the cancellation is Host, Guest or Playace initiated.
        </p>
        <h5 className="my-4">How to cancel a booking</h5>
        <p>
          To cancel a Booking you must submit a request to cancel using the
          “Cancel Booking” designations on the Platform (mobile app or website).
          Cancellation requests cannot be submitted through messages, phone
          calls, chats, or emails to Playace. If you are unable to locate the
          Cancel Booking icons on the Platform, please contact our Support team.
          Submitted cancellation requests may be delayed up to 48 hours to allow
          either party to reconfirm, reschedule, or finalize the cancellation.
          After such period, the cancellation will be finalized.
        </p>
        <h5 className="my-4">Rescheduled bookings</h5>
        <p>
          Where approved by Playace, a Guest or Host may have an opportunity to
          reschedule that Booking. Any rescheduled Bookings must be:
        </p>
        <ul>
          <li>
            For substantially the same duration as the original Booking,
            according to the Host’s approval;
          </li>
          <li>Confirmed prior to the original Start Time;</li>
          <li>
            Rescheduled for a time within 90 days of the original Start Time;
            and
          </li>
          <li>Only rescheduled once.</li>
        </ul>
        <p>
          Any rescheduled Bookings are non-refundable once confirmed. Any
          successive rescheduling attempts or failure to successfully reschedule
          a Booking within the conditions above will result in a cancellation of
          the Booking, subject to the Cancellation Fees or Fines identified in
          this Policy, depending on the initiating party. Any rescheduling by
          Guests may be subject to an additional Processing Fee payable to
          Playace.
        </p>
      </div>
    </Container>
  );
}
