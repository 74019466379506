import React from "react";
import { useSelector } from "react-redux";
// import axios from 'axios';
import {
  fetchEventsByHostId,
  fetchProfile,
  fetchReviewsByHost,
  fetchUser,
  getInterests,
} from "../../../../api/requests";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import HostedParties from "../../../pages/Home/hostedParties";
import { getLoader, showStarRating } from "../../../../utils/utils";
import EventHostImages from "../../../pages/Home/eventHostImages";

const apiRequest = {
  page: 1,
  limit: 4,
};

function HostDetails() {
  const { hostId, eventId } = useParams();
  const isAuthenticated = useSelector((state) => state.auth);
  const isData = useSelector((state) => state.auth.data);
  let userObj = {};

  if (isAuthenticated.isAuthenticated) {
    userObj = JSON.parse(isData ? isData : []);
  }

  const history = useHistory();
  const [loading, setLoader] = useState(false);
  const [events, setEvents] = useState([]);

  const [profileData, setProfileData] = useState({});
  const [interestsList, setInterests] = useState([]);
  const [pastImages, setPastImages] = useState([]);
  const [hostReviewData, setHostReviewData] = useState({
    reviews: [],
    totalCount: 0,
    totalPages: 0,
    ratingAverage: 0,
  });
  const [showMore, setShowMore] = useState(true);

  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    occupation: "Accountant",
    languages: "English, Marathi",
    location: "Mumbai",
    uselessSkill: "I can sing Lorem Ipsum",
    favoriteMovie: "Chennai Express",
    favoriteTVShow: "Brooklyn 99",
    funFact: "Chennai Express",
  });

  const [selectedInterests, setSelectedInterests] = useState([]);

  const callApis = async () => {
    const response = await getInterests();

    if (response.status === "success") {
      setInterests(response.data);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }

    callApis();
  }, [isAuthenticated, history]);

  useEffect(() => {
    setLoader(true);
    fetchUser("fetchUser", null, hostId)
      .then((response) => {
        setFormData(response?.data?.about);
        setPastImages(response?.data?.eventsImages);
        setSelectedInterests(response?.data?.interest?.map((item) => item.id));
        setProfileData(response?.data);
      })
      .finally(() => {
        setLoader(false);
      });

    fetchEventsByHostId(hostId, null).then((response) => {
      setEvents(response?.data);
    });
    apiRequest.page = 1;
    apiRequest.limit = 4;
    callReviewApi();
  }, []);

  const callReviewApi = async () => {
    try {
      const response = await fetchReviewsByHost(
        hostId,
        apiRequest.page,
        apiRequest.limit
      );
      if (hostReviewData) {
        setHostReviewData((prevState) => ({
          reviews: [...prevState.reviews, ...response?.data?.reviews],
          totalCount: response.data.totalCount,
          totalPages: response.data.totalPages,
          ratingAverage: response.data.ratingAverage,
        }));
      } else {
        setHostReviewData(response.data);
      }
      if (response?.data?.totalPages > apiRequest.page) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    } catch {}
  };

  const loadMoreReview = () => {
    apiRequest.limit = 4;
    apiRequest.page = apiRequest.page + 1;
    callReviewApi();
  };

  const getSuccessTickSVG = (className = "correctIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-check"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
    );
  };

  const getFailedTickSVG = (className = "wrongIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-mark"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 19v.01" />
          <path d="M12 15v-10" />
        </svg>
      </div>
    );
  };

  const getVerificationView = (
    statusId = 3,
    statusMessage = "Pending",
    isLoading = false
  ) => {
    switch (statusId) {
      case 0:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}

              <p>Government ID</p>
            </div>
          </div>
        );
      case 1:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>

              <div className="verifyDocs">{statusMessage}</div>
            </div>
          </div>
        );
      case 2:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getSuccessTickSVG()}
              <p>Government ID</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div class Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>
              <div className="verifyDocs">{statusMessage}</div>
            </div>
          </div>
        );

      default:
        return (
          <button
            id="add-governmentId"
            onClick={() => {
              // setDisableButton(true);
              // startOnFidoVerification(userObj, changeVerificationStatus);
            }}
            className="make-pointer border-0 btn btn-primary fw-bold text-uppercase"
            style={{ fontSize: "0.75rem" }}
          >
            ADD
          </button>
        );
    }
  };

  if (loading) {
    return getLoader();
  }

  return (
    <>
      <div className="container">
        <div className="profileGridCommon">
          <div className="profileCard">
            <div className="profieInner">
              <div className="userImgBg">
                <img src={profileData?.profileImage} alt="" />
              </div>
              <div className="userIntro">
                <p>Hi, I am</p>
                <h1>{profileData?.fullName}</h1>
              </div>

              <div className="userInfo">
                <div className="d-flex">
                  <div className="col">
                    <h3>{profileData?.partiesAttended}</h3>
                    <p>Parties attended</p>
                  </div>
                  {profileData?.partiesHosted > 0 && (
                    <div className="col">
                      <h3>{profileData?.partiesHosted}</h3>
                      <p>Parties hosted</p>
                    </div>
                  )}
                  {profileData?.averageRating > 0 && (
                    <div className="col">
                      <div className="d-flex rateC">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="icon icon-tabler icons-tabler-filled icon-tabler-star"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"></path>
                        </svg>
                        <h3>
                          {profileData?.averageRating}{" "}
                          <span className="outOf">/5</span>
                        </h3>
                      </div>
                      <p>Avg. rating</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="verifiedSection">
                <h2>Verified Information</h2>

                <div className="listOfDocs">
                  <div className="listCommon">
                    <div className="ll">
                      {profileData?.isPhoneVerified
                        ? getSuccessTickSVG()
                        : getFailedTickSVG()}
                      <p>Phone number</p>
                    </div>
                  </div>

                  {getVerificationView(
                    profileData?.statusId,
                    profileData?.statusMessage
                  )}

                  <div className="listCommon">
                    <div className="ll">
                      {profileData?.statusId === 2
                        ? getSuccessTickSVG()
                        : getFailedTickSVG()}
                      <p>Profile Status</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="profileContents">
            <div>
              <div className="userQualitiesCont sameMarbtm">
                <h2 className="profileTitsCom">
                  {" "}
                  About {profileData?.fullName}
                </h2>
              </div>
              <div className="row">
                <div className="col">
                  {edit ? (
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                            <path d="M12 12l0 .01" />
                            <path d="M3 13a20 20 0 0 0 18 0" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          name="occupation"
                          value={formData?.occupation}
                        />
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-message"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 9h8" />
                            <path d="M8 13h6" />
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          name="languages"
                          value={formData?.languages}
                        />
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                          </svg>
                        </div>

                        <input
                          type="text"
                          name="location"
                          value={formData?.location}
                        />
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
                            <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          name="funFact"
                          value={formData?.funFact}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                            <path d="M12 12l0 .01" />
                            <path d="M3 13a20 20 0 0 0 18 0" />
                          </svg>
                        </div>
                        <p>I am {formData?.occupation}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-message"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 9h8" />
                            <path d="M8 13h6" />
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                          </svg>
                        </div>
                        <p>I speak in {formData?.languages}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                          </svg>
                        </div>
                        <p>I live in {formData?.location}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
                            <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
                          </svg>
                        </div>
                        <p>A fun fact about me is {formData?.funFact}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col">
                  {edit ? (
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-wand"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                            <path d="M15 6l3 3" />
                            <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                            <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                          </svg>
                        </div>

                        <input
                          type="text"
                          name="uselessSkill"
                          value={formData?.uselessSkill}
                        />
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-movie"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M8 4l0 16" />
                            <path d="M16 4l0 16" />
                            <path d="M4 8l4 0" />
                            <path d="M4 16l4 0" />
                            <path d="M4 12l16 0" />
                            <path d="M16 8l4 0" />
                            <path d="M16 16l4 0" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          name="favoriteMovie"
                          value={formData?.favoriteMovie}
                        />
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-device-tv-old"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3l-4 4l-4 -4" />
                            <path d="M15 7v13" />
                            <path d="M18 15v.01" />
                            <path d="M18 12v.01" />
                          </svg>
                        </div>
                        <input
                          type="text"
                          name="favoriteTVShow"
                          value={formData?.favoriteTVShow}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-wand"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                            <path d="M15 6l3 3" />
                            <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                            <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                          </svg>
                        </div>

                        <p>My most useless skill is {formData?.uselessSkill}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-movie"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M8 4l0 16" />
                            <path d="M16 4l0 16" />
                            <path d="M4 8l4 0" />
                            <path d="M4 16l4 0" />
                            <path d="M4 12l16 0" />
                            <path d="M16 8l4 0" />
                            <path d="M16 16l4 0" />
                          </svg>
                        </div>
                        <p>My favourite movie is {formData?.favoriteMovie} </p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-device-tv-old"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3l-4 4l-4 -4" />
                            <path d="M15 7v13" />
                            <path d="M18 15v.01" />
                            <path d="M18 12v.01" />
                          </svg>
                        </div>
                        <p>
                          My favourite TV show is {formData?.favoriteTVShow}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="sameMarbtm">
                <h2 className="profileTitsCom">Interests</h2>
              </div>
              <div className="selectedIntCont">
                {selectedInterests?.map((id, index) => (
                  <span className="fakeBadge" key={index}>
                    {
                      interestsList?.find(
                        (interest) => interest?.interestId === id
                      )?.interestName
                    }
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="marSpace">
          <HostedParties data={events} title=""></HostedParties>
        </div>

        {pastImages?.length > 0 && (
          <div className="pastPartyImagesCont">
            <h2 className="partiesHostedTit mobmbzero">
              Previous Party Images
            </h2>
            {pastImages?.length > 1 ? (
              <div className="row">
                <div className="col">
                  <EventHostImages images={pastImages} />
                </div>
              </div>
            ) : (
              <div className="staticPrevImg">
                <img src={pastImages[0]} alt="" />
              </div>
            )}
          </div>
        )}

        {hostReviewData?.totalCount > 0 && (
          <div className="ratingsCont">
            <h2 className="profileTitsCom">
              What guests say about {profileData?.fullName}
            </h2>

            <div className="ratingCardsCont">
              {hostReviewData?.reviews?.map((review) => {
                return (
                  <div className="ratingCard">
                    <div className="profileratings">
                      <div className="profImg">
                        <img src={review?.profileImage} alt="" />
                      </div>
                      <div className="profNameNStar">
                        <h4>{review?.userName}</h4>
                        <div className="starsCont">
                          {showStarRating(review?.rating)}
                        </div>
                      </div>
                    </div>

                    <div className="ratingText">
                      <p>{review?.comment}</p>
                    </div>
                  </div>
                );
              })}

              {showMore && (
                <div className="d-flex justify-content-center">
                  <b
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={loadMoreReview}
                  >
                    View More
                  </b>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HostDetails;
