import React, { useEffect, useState } from 'react';
import { Skeleton } from '../../../modules/common';
import { Row, Col, Pagination } from 'react-bootstrap';
import { EventCard } from '../../../modules/content';

export default function All({ currentPage, userData, handlePageChange, handleFavoriteChange, ...props }) {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    const allEvents = props.data ? props.data : [];
    const allPages = props.pagination ? props.pagination.totalPages : 0;
    setCurrentEvents(allEvents);
    setTotalPages(allPages);
  }, [currentPage, props.data, props.pagination])

  return (
    <div onClick={scrollToTop}>
      {!props.data && (
        <div className="grid-container">
          {[...Array(12)].map((_, index) => {
            return <Skeleton key={index} />;
          })}
        </div>
      )}

      <Row className={props.data && props.data.length <= 0 ? 'd-flex justify-content-center' : ''}>
        {currentEvents && currentEvents.length > 0 ? (
          currentEvents.map((item, index) => (
            <Col key={index} md={4} className="mb-3">
              <EventCard
                type="party"
                {...item}
                userData={userData}
                handleFavoriteChange={handleFavoriteChange}
              />
            </Col>
          ))
        ) : (
          <div>
            <h2 style={{ marginLeft: "80px" }}>No events found :(</h2>
          </div>
        )}
      </Row>
      {totalPages > 1 && (
        <div className="d-flex justify-content-center mt-3">
          <Pagination>
            {Array.from(Array(totalPages).keys()).map((page) => (
              <Pagination.Item
                key={page}
                active={currentPage == page + 1}
                onClick={() => handlePageChange(page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      )}
    </div>
  );
}