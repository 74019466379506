import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./PartiesFilterNav.module.scss";
import { fetchCities } from "../../../api/requests";
export default function PartiesFilterNav({
  filter,
  userData,
  currentLocation,
  handleLocationFilter = () => { },
}) {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth);
  const [locationData, setLocationData] = useState([]);

  const updateFilterType = (filterType) => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set("filter", filterType);
    history.push({ search: urlParams.toString() });
  }



  const updateUrlWithCity = (city) => {
    const urlParams = new URLSearchParams(window.location.search);
    if (city) {
      urlParams.set("city", city);
    } else {
      urlParams.delete("city");
    }
    history.push({ search: urlParams.toString() });
  };

  const handleLocationSelection = (location) => {
    handleLocationFilter(location);
    updateUrlWithCity(location);
  };

  // useEffect(() => {
  //   fetchCities().then((response) => {
  //     const dropdownOptions = response?.data?.map((option) => ({
  //       label: option,
  //       value: option,
  //     }));
  //     setLocationData(dropdownOptions);

  //     if (currentLocation.length > 0) {
  //       const cityExists = dropdownOptions.some(
  //         (location) =>
  //           location.value.toLowerCase() === currentLocation[0].toLowerCase()
  //       );
  //       if (cityExists) {
  //         updateUrlWithCity(currentLocation[0]);
  //         handleLocationFilter(currentLocation[0]);
  //       }
  //     }

  //     var cityFromURL = new URLSearchParams(window.location.search).get("city");

  //     console.log("cityFromURL", cityFromURL);

  //     if (cityFromURL) {
  //       const cityMatched = dropdownOptions.find(
  //         (location) =>
  //           location.value.toLowerCase() === cityFromURL.toLowerCase()
  //       );

  //       if (cityMatched) {
  //         handleLocationSelection(cityMatched.value);
  //       }
  //     }
  //   });
  // }, [userData?.token]);

  return (
    <div className="filters-container">
      <button
        onClick={() => {
          updateFilterType("all");
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "all" ? "selected-filter" : ""
          }`}
      >
        <small>All</small>
      </button>
      <button
        onClick={() => {
          updateFilterType("today");
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "today" ? "selected-filter" : ""
          }`}
      >
        <small>Today</small>
      </button>
      <button
        onClick={() => {
          updateFilterType("tomorrow");
        }}
        className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "tomorrow" ? "selected-filter" : ""
          }`}
      >
        <small>Tomorrow</small>
      </button>
      {/* <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          style={{
            color:
              currentLocation.length > 0 &&
                locationData.some(
                  (location) => location.value === currentLocation[0]
                )
                ? "#ffffff"
                : "#212529",
            backgroundColor:
              currentLocation.length > 0 &&
                locationData.some(
                  (location) => location.value === currentLocation[0]
                )
                ? "#FF1844"
                : "inherit",
          }}
          className={`${styles["dropdown-toggle"]} btn btn-small btn-reg mx-1 filter-btn`}
        >
          <small>Location</small>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {locationData.length > 0 ? (
            locationData.map((location) => (
              <Dropdown.Item
                key={location.value}
                onClick={() => handleLocationSelection(location.value)}
                style={{
                  backgroundColor:
                    location.value === currentLocation[0]
                      ? "#FF1844"
                      : "inherit",
                  color:
                    location.value === currentLocation[0]
                      ? "#ffffff"
                      : "inherit",
                }}
              >
                {location.label}
              </Dropdown.Item>
            ))
          ) : (
            <div>No location data available.</div>
          )}
        </Dropdown.Menu>
      </Dropdown> */}
      {isAuthenticated?.isAuthenticated && (
        <button
          onClick={() => {
            updateFilterType("favorites");
          }}
          className={`btn btn-small btn-reg mx-1 filter-btn ${filter === "favorites" ? "selected-filter" : ""
            }`}
        >
          <FontAwesomeIcon icon={faHeart} size="lg" />
        </button>
      )}
    </div>
  );
}
