import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
// import { EventCard } from './PartyBookingRequestReceived';
import Img from 'react-cool-img';
import { Loader } from '../../../../helpers';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchHostingRequests } from '../../../../../api/requests';
import PlaceholderImg from '../../../../../assets/house_party_placeholder.jpg';

export default function PartyHostingRequestSent(props) {
	const { data, isLoading } = useQuery(
		['FETCH_HOSTING_REQUESTS', props.token],
		fetchHostingRequests,
		{
			refetchOnWindowFocus: false,
		}
	);

	if (isLoading) {
		return (
			<div
				style={{ minHeight: '70vh', margin: '7rem auto', textAlign: 'center' }}
			>
				<Loader height="80px" width="80px" />
			</div>
		);
	}

	return (
		<div>
			<p className="text-uppercase text-primary">Hosting Requests Sent</p>
			{!isLoading && data && data.length === 0 && (
				<p>No hosting requests sent.</p>
			)}
			{!isLoading &&
				data &&
				data.map(party => {
					return (
						<React.Fragment key={v4()}>
							<EventCard requests={party} />
							<hr />
						</React.Fragment>
					);
				})}
			{/* <EventCard requests={data} /> */}
		</div>
	);
}

export function EventCard(props) {
	const [hostingStatus, setHostingStatus] = useState('');

	useEffect(() => {
		if (props.requests.status === 0) setHostingStatus('Submitted');
		else if (props.requests.status === 1) setHostingStatus('Under Review');
		else if (props.requests.status === 2) setHostingStatus('Accepted');
		else setHostingStatus('Cancelled');
	}, []);

	return (
		// <Link to={`/parties/${props.event_id}`} className="link-unstyled">
		<div className="d-flex align-items-center">
			<div className="col-md-6">
				<small className="text-muted d-block">
					Tentative Date :{' '}
					{moment(props.requests.tentative_date).format('MMM DD')}
				</small>
				<small className="text-muted d-block">Status : {hostingStatus}</small>
			</div>
			<div className="col-md-6">
				<small className="text-muted d-block">
					Created At: {moment(props.requests.created_at).format('MMM DD')}
				</small>
			</div>
		</div>
		// </Link>
	);
}
