import React from "react";
import { Row, Col } from "react-bootstrap";
import { Card } from "../Profile";

import {
  PartyBookingRequestReceived,
  PartyBookingRequestSent,
  PartyHostingRequestSent,
} from "./Parties";

import { useSelector } from "react-redux";
import "./index.scss";

function PendingRequests() {
  const auth = JSON.parse(useSelector((state) => state.auth.data));
  return (
    <div className="mx-3">
      <div>
        <h3 className="text-primary fw-bold">House Parties</h3>
        <div>
          <Row>
            <Col md={4} sm={12} className="mb-3">
              <Card className="p-3">
                <PartyBookingRequestReceived token={auth.token} />
              </Card>
            </Col>
            <Col md={4} sm={12} className="mb-3">
              <Card className="p-3">
                <PartyBookingRequestSent token={auth.token} />
              </Card>
            </Col>
            <Col md={4} sm={12}>
              <Card className="p-3">
                <PartyHostingRequestSent token={auth.token} />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default PendingRequests;
