import {
    UPDATE_GUEST_COUNT, UPDATE_PROMO_CODE,
    UPDATE_QUOTATION_DATA
} from '../actions/types';

const initialState = {
    guestCount: 1,
    promoCode: "",
    quotationData: {}
};

const guestBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_GUEST_COUNT:
            return {
                ...state,
                guestCount: action.payload
            };
        case UPDATE_PROMO_CODE:
            return {
                ...state,
                promoCode: action.payload
            };

        case UPDATE_QUOTATION_DATA:
            return {
                ...state,
                quotationData: action.payload
            };

        default:
            return {
                ...state,
            };
    }
};

export default guestBookReducer;