import React, { useState } from "react";
import { Container } from "react-bootstrap";
import NavTab from "../TermsAndConditions/NavTab";
import HouseParties from "./HouseParties";
import SpaceListing from "./SpaceListing";

export default function Refunds() {
  let [Tab, setTab] = useState("parties");
  return (
    <div style={{ marginTop: "7rem" }}>
      <NavTab Tab={Tab} setTab={setTab} />
      <Container className="extraFontsCont">
        {Tab === "parties" ? <HouseParties /> : <SpaceListing />}
      </Container>
    </div>
  );
}
