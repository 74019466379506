import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import tawkToScript from "../../helpers/tawkTo";

import { Container, Modal } from "react-bootstrap";

import successfulPayment from "../../assets/3 copy.svg";

import Slide from "./Slide";
import Ahe from "./Ahe";
import Attend from "./Attend";
// import CTA from './CTA';
import Spaces from "./Spaces";
import Services from "./Services";
import Testimonial from "./Testimonial";

import "../../assets/index.scss";
import styles from "../../modules/auth/Auth.module.scss";
import Faq from "./Faq";
import HostPartyBanner from "./HostPartyBanner";
import ExperienceVideos from "./ExperienceVideos";
import SmallInfo from "./SmallInfo";
import Brands from "./Brands";
import HeroSection from "./HeroSection";
import VerifiedInfo from "./VerifiedInfo";
import AdBanners from "./AdBanners";
import discoBall from "../../../assets/image 7.svg";
import clebrate from "../../../assets/Frame 656 (1).png";
import { useDispatch } from "react-redux";
import { updateIsHost } from "../../../actions/authActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Home() {
  let [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isShowHostRequestModel, setShowHostRequestModel] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const tawkToPropertyId = "5abf59814b401e45400e3a26";

  const tawkToKey = "8694642484d36335b7b0752f7689e55b60e248c3";

  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const { paid, title, locationOg, city, eventDate, partyImage } =
    Object.fromEntries(new URLSearchParams(window.location.search));
  useEffect(() => {
    // tawkToScript(tawkToPropertyId, tawkToKey);
    ReactPixel.init("1890035134379309");
    ReactPixel.track("PageView");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // Check if the 'paid' parameter is 'true'
    if (queryParams.get("paid") === "true") {
      console.log("TRUEEE");
      setShowPaymentModal(true);
    }
    if (location.search === "?requested=true") {
      setShowPaymentModal(true);
    }
    if (location.search === "?enquiry=true") {
      setShowPaymentModal(true);
    }
    if (location?.pathname === "/") {
      dispatch(updateIsHost(false));
    }
    // console.log(location);
  }, [location]);

  return (
    <>
      <div className="">
        {/* <Slide /> */}

        <HeroSection />
        <Attend button={true} />
        <AdBanners isEvent={false} />

        {/* <CTA /> */}
        {/* <Spaces button={true} /> */}
        {/* <Testimonial /> */}
        <Brands />
        <SmallInfo />
        <VerifiedInfo />
        <ExperienceVideos />
        <HostPartyBanner diff="Why just attend? Host regularly and earn up to ₹40,000!"
          isShowHostRequestModel={isShowHostRequestModel} setShowHostRequestModel={setShowHostRequestModel} />
        <Faq />
      </div>

      <Modal
        show={showPaymentModal}
        onHide={() => {

          history.replace({ search: "" });
          setShowPaymentModal(false);
        }}
        centered
      >
        <div style={{ padding: "20px" }}>
          <img src={clebrate} alt="" className="absoluteImg" />

          <h2 className="suctit">Yay, We’ve got your request !</h2>

          <div className="eventDetailsSuccess">
            <div className="flexCenter gap15">
              <div className="smolCardImg">
                <img src={partyImage} alt="" />
              </div>

              <div className="rightEventDetails">
                <h1>{title}</h1>

                <div className="eventDetGap">
                  <div className="locationNdate flexCenter">
                    <div className="dateCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                          <path d="M16 3l0 4" />
                          <path d="M8 3l0 4" />
                          <path d="M4 11l16 0" />
                          <path d="M8 15h2v2h-2z" />
                        </svg>
                      </div>
                      <p className="ellipsis"> {eventDate}</p>
                    </div>
                    <div className="locationCont flexCenter">
                      <div className="iconSvg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                        </svg>
                      </div>
                      <p className="ellipsis">
                        {locationOg} {","} {city}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="verySmallP">
            You will receive Party updates on Whatsapp as well as on email.
          </p>
          {/* <Link to="/dashboard">
            <button className="whiteFull">Okay</button>
          </Link> */}

          <div className="profileVerificationCont text-center">
            <h4>
              Please verify your profile to proceed. If it's already done,
              you're good to go!
            </h4>
            <Link to="/dashboard">
              <button className="whiteFull">Continue</button>
            </Link>
          </div>
        </div>

        {/* <button
          className={`${styles.closebtn} bg-primary text-white rounded border-0`}
          onClick={() => {
            setShowPaymentModal(false);
          }}
        >
          X
        </button>
         */}
        {/* <Container className=" text-center py-5">
          <img
            src={successfulPayment}
            alt="payment successful playace"
            height="150"
            width="150"
          />
          <h3 className="fw-bold">
            {location.search === "?paid=true"
              ? "PAYMENT SUCCESSFUL!"
              : location.search === "?enquiry=true"
                ? "ENQUIRY SUCCESSFUL"
                : "REQUEST SENT TO THE HOST!"}
          </h3>
          <p>
            {location.search === "?paid=true" ||
              location.search === "?requested=true"
              ? "Your request is being reviewed. You will be notified shortly."
              : "Your enquiry was sent successfully. We'll email you with updates soon."}
          </p>
          <Link
            to={`/parties?page=1`}
            className="btn btn-reg btn-primary fw-bold btn-padding"
          >
            Explore more parties
          </Link>
        </Container> */}
      </Modal>
    </>
  );
}

// export default React.memo(Home);
