import first from "./1.png";
import second from "./2.png";
import third from "./3.png";
import fourth from "./4.png";
import fifth from "./5.png";

export const Testimonials = [
    {
        image: first,
        name: 'Siddhart',
        video: 'https://youtu.be/6MGD46ycook?si=Sb6TrDU0zkg46VTi',
    },
    {
        image: second,
        name: 'Harshita',
        video: 'https://youtu.be/Xw9HfLSTYhQ?si=L2cMg8xWipFGK890',

    },
    {
        image: third,
        name: 'Mariam',
        video: 'https://www.youtube.com/watch?v=ftpoTNATLAw',

    },
    {
        image: fourth,
        name: 'Surabhi',
        video: 'https://www.youtube.com/watch?v=wJdeU2fM9GU',

    },
    {
        image: fifth,
        name: 'Ashmita',
        video: 'https://youtu.be/M_mE_i2fvK8?si=hV5hU020Z1fxJtCk',

    }

];