import React from 'react';
import { useHistory } from 'react-router-dom';

export default function NotFoundPage({ text }) {
    const history = useHistory();

    return (
        <div className="d-flex flex-column align-items-center justify-content-center text-center" style={{ height: "100vh", backgroundColor: "#161719" }}>
            <h1 className="display-1 font-weight-bold" style={{ color: "#fff", fontSize: "5rem" }}>404</h1>
            <h3 className="text-secondary mb-4" style={{ fontSize: "1.3rem" }}>{text ?? "Oops! The page"} you are looking for does not exist.</h3>
            {/* <p className="text-muted mb-5" style={{ maxWidth: "400px" }}>
                It looks like the page you’re trying to reach isn’t available. Please check the URL or navigate back to safety using the buttons below.
            </p> */}
            <div>
                <button className="btn btn-lg btn-outline-primary mr-3" onClick={() => history.goBack()}>
                    <i className="fas fa-arrow-left mr-2"></i> Go Back
                </button>
                <button className="btn btn-lg btn-primary" onClick={() => history.push("/")}>
                    <i className="fas fa-home mr-2"></i> Go Home
                </button>
            </div>
        </div>
    )

}