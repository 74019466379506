import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Skeleton } from "../../modules/common";
import {
  getAllEventsByDateAndLocation,
  getAllFavoriteEvents,
} from "../../../api/requests";
import PartiesFilterNav from "./PartiesFilterNav";
import { All } from "./Filters";
import { RenderUserObj, ScrollToTop } from "../../helpers";
import { useParams } from "react-router-dom";
import { Default, Mobile } from "../../helpers/Breakpoints";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";


function Parties() {
  const [eventData, setEventData] = useState(null);
  const [paginationData, setPaginationData] = useState(null);
  const [filterLocation, setFilterLocation] = useState([]);
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { pagenumber } = useParams();
  const rand = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const userData = RenderUserObj(useSelector((state) => state.auth.data));
  const todayDate = moment().format("YYYY-MM-DD");
  const tomorrowDate = moment().add(1, "day").format("YYYY-MM-DD");
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const search = urlParams.get('search');
  const city = urlParams.get('city') ?? "";
  const page = urlParams.get('page') ?? 1;
  const filterType = urlParams.get('filter') ?? "all";




  const handleFavoriteEvents = () => {
    let payload = {
      userId: userData?.data?.userId
    }

    setIsLoading(true);
    getAllFavoriteEvents(userData?.token, payload)
      .then((response) => {
        if (response.status === "success") {
          setData(response);
          setEventData(response.data);
          console.log("response.pagination::::", response.pagination);
          setPaginationData(response.pagination);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handlePageChange = (page) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('page', page);
    history.push({ search: urlParams.toString() });
  }

  useEffect(() => {
    return () => {
      history.replace(`?`);
    }
  }, []);

  useEffect(() => {
    console.log("location.search:::", location.search);
    const urlParams = new URLSearchParams(location.search)
    const city = urlParams.get('city');
    if (!city) {
      return;
    }

    fetchEvents();
  }, [location.search]);


  const fetchEvents = () => {
    if (filterType == "favorites") {
      handleFavoriteEvents();
      return;
    }

    let payload = {
      "fromDate": "",
      "toDate": "",
      search: search ?? "",
      page: page,
      limit: 9,
      cities: city ? [parseInt(city)] : []
    }

    switch (filterType) {
      case "today":
        payload.fromDate = todayDate;
        payload.toDate = todayDate;
        break;
      case "tomorrow":
        payload.fromDate = tomorrowDate;
        payload.toDate = tomorrowDate;
        break;
      default:
        payload.fromDate = "";
        payload.toDate = "";
        break;
    }

    setIsLoading(true);
    getAllEventsByDateAndLocation(payload)
      .then((response) => {
        if (response.status === "success") {
          setData(response);
          setEventData(response.data);
          setPaginationData(response.pagination);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

  }

  return (
    <Container className="mTop mb-5 padtopMobile">
      {isLoading ? <ScrollToTop /> : null}
      <div>
        <Default>
          <Row className="mt-5 mb-3">
            <Col lg={7} sm={12} />
            <Col lg={5} sm={12}>
              <PartiesFilterNav
                filter={filterType}
                data={data}
                userData={userData}
                currentLocation={filterLocation}
              />
            </Col>
          </Row>
        </Default>
        <Mobile>
          <PartiesFilterNav
            filter={filterType}
            data={data}
            userData={userData}
            currentLocation={filterLocation}
          />
        </Mobile>
      </div>

      {isLoading && (
        <div className="grid-container">
          {rand.map((item, index) => {
            return <Skeleton key={index} />;
          })}
        </div>
      )}

      {!isLoading && eventData && (
        <All
          currentPage={page}
          data={eventData}
          pagination={paginationData}
          userData={userData}
          handlePageChange={handlePageChange}
          handleFavoriteChange={fetchEvents}
        />
      )}
    </Container>
  );
}

export default React.memo(Parties);
