import {
  UPDATE_HOST_PARTY_OBJECT,
  SEND_HOST_PARTY_REQUEST,
  SEND_HOST_PARTY_REQUEST_SUCCESS,
  SEND_HOST_PARTY_REQUEST_FAILURE,
} from "../actions/types";

const initialState = {
  data: {
    name: "",
    alcohol: "",
    food: 0,
    type: 1,
    smoking: 1,
    description: ``,
    pet: 0,
    guest_pet: 0,
    visibility: 1,
    elevator: 0,
    parking: 0,
    child: 0,
    price: 100,
    accommodate_people: 10,
    party_date: "",
    start_time: "",
    end_time: "",
    last_date: "",
    state_id: "",
    city_id: "",
    locality: "",
    address: "",
    pincode: "",
    food_type: 0,
    bank_holder_name: "",
    bank_account_no: "",
    bank_name: "",
    bank_branch: "",
    bank_ifsc: "",
    bank_pan_card: "",
  },
  loading: false,
  errors: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_HOST_PARTY_OBJECT:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case SEND_HOST_PARTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_HOST_PARTY_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case SEND_HOST_PARTY_REQUEST_FAILURE:
      return { ...state, errors: action.payload, loading: false };
    default:
      return {
        ...state,
      };
  }
}
