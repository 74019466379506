import axios from "axios";
import {
  UPDATE_HOST_PARTY_OBJECT,
  SEND_HOST_PARTY_REQUEST_SUCCESS,
  SEND_HOST_PARTY_REQUEST_FAILURE,
} from "../actions/types";

import { URL } from "../api/requests";

export const updateHostPartyObject = (data) => ({
  type: UPDATE_HOST_PARTY_OBJECT,
  payload: data,
});

export const sendHostPartyRequestSuccess = (data) => {
  window.location.href = `${process.env.REACT_APP_BASE_URL}?host_request=true`;
  return {
    type: SEND_HOST_PARTY_REQUEST_SUCCESS,
    payload: data,
  };
};

export const sendHostPartRequestFailure = (errors) => ({
  type: SEND_HOST_PARTY_REQUEST_FAILURE,
  payload: errors,
});

export const sendHostPartyRequest = (data, usertoken) => {
  return (dispatch) => {
    dispatch(updateHostPartyObject(data));
    axios
      .post(`${URL}v1/host-party`, data, {
        headers: {
          Authorization: `Bearer ${usertoken}`,
        },
      })
      .then((response) => {
        dispatch(sendHostPartyRequestSuccess(response.data));
      })
      .catch((errors) => {
        dispatch(sendHostPartRequestFailure(errors.errors));
      });
  };
};
